import { Route } from '@angular/router';

import { PublicTreesComponent } from './public-trees.component';

export const PUBLIC_TREES_ROUTE: Route = {
  path: 'public',
  component: PublicTreesComponent,
  data: {
    authorities: [],
    pageTitle: 'home.title'
  }
};
