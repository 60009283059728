import { Component, OnInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { NbDialogRef, NbDateService } from '@nebular/theme';
import { FormBuilder, Validators } from '@angular/forms';
import { JhiDataUtils, JhiEventManager, JhiFileLoadError, JhiEventWithContent } from 'ng-jhipster';
import { AlertError } from 'app/shared/alert/alert-error.model';
import { PersonService } from 'app/entities/person/person.service';
import { IPerson, PersonWithAvatar } from 'app/shared/model/person.model';
import { IParent, Parent } from 'app/shared/model/parent.model';
import { ISpouse, Spouse } from 'app/shared/model/spouse.model';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { HttpResponse } from '@angular/common/http';
import { DATE_FORMAT } from 'app/shared/constants/input.constants';
import { SessionStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';
import { hashObject } from 'app/shared/util/my-functions.util';
import { ParentService } from 'app/entities/parent/parent.service';
import { SpouseService } from 'app/entities/spouse/spouse.service';
import { SERVER_API_URL } from 'app/app.constants';

@Component({
  selector: 'jhi-member-add',
  templateUrl: './member-add.component.html',
  styleUrls: ['./member-add.component.scss']
})
export class MemberAddComponent implements OnInit {
  @Output()
  afterMemberSaved = new EventEmitter<any>();

  currentTree: any = null;

  currentMember: any = null;

  currentMemberImage: any = '';

  // Utilisé pour afficher le formulaire d'ajout ou pas
  showForm = false;

  // Permet de savoir si l'utilisateur veut renseigner une relation avec un membre deja existant ou pas
  relativeExist = true;

  // Permet de savoir le type de relation avec la personne que l'on veut ajouter
  relationship = '';

  // Les proches possibles que l'on peut affecter au membre courant
  possibleRelatives: IPerson[] | null = [];

  possibleRelativesHashed: any;

  // La valeur maximale qu'il est possible d'affecter a une date
  dateMax: Date;

  existingRelative: any = null;

  selectedRelative: any;

  // Permet de savoir si l'utilisateur a valider le formulaire ou pas
  isSaving = false;

  isSavingRelation = false;

  // person = new FamilyMember();

  newMemberForm = this.fb.group({
    id: [],
    firstName: [
      null,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
        Validators.pattern(
          "^[A-Z]([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+(([ ][A-Z]{1})?([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+)*$"
        )
      ]
    ],
    lastName: [
      null,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(25),
        Validators.pattern(
          "^[A-Z]([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+(([ ][A-Z]{1})?([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+)*$"
        )
      ]
    ],
    gender: [null, [Validators.required]],
    dateBirth: [],
    placeBirth: [],
    dateDeath: [],
    placeDeath: [],
    defaultImage: [],
    defaultImageContentType: [],
    parentOf: [],
    childOf: [],
    husbandOf: [],
    wifeOf: []
    // dateAdded: [],
    // dateModified: [],
    // addedById: [null, Validators.required],
    // lastUpdateById: []
  });

  constructor(
    protected dialogRef: NbDialogRef<any>,
    protected elementRef: ElementRef,
    protected _sessionSt: SessionStorageService,
    private fb: FormBuilder,
    protected dataUtils: JhiDataUtils,
    protected eventManager: JhiEventManager,
    protected personService: PersonService,
    protected parentService: ParentService,
    protected spouseService: SpouseService,
    protected dateService: NbDateService<Date>
  ) {
    this.dateMax = this.dateService.today();
  }

  ngOnInit(): void {
    this.currentMember = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_MEMBER);
    this.currentTree = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE);
    if (this.currentMember.avatarImg != null) {
      this.currentMemberImage = SERVER_API_URL + this.currentMember.avatarImg;
    } else if (this.currentMember.imageContentType != null) {
      this.currentMemberImage = 'data:' + this.currentMember.imageContentType + ';base64,' + this.currentMember.image;
    } else if (this.currentMember.gender === 'MASCULIN') {
      this.currentMemberImage = Constantes.MEMBER_DEFAULT_IMAGE_MALE;
    } else {
      this.currentMemberImage = Constantes.MEMBER_DEFAULT_IMAGE_FEMALE;
    }

    this._sessionSt.observe(Constantes.SESSION_CURRENT_TREE).subscribe(value => (this.currentTree = value));
    this._sessionSt.observe(Constantes.SESSION_CURRENT_MEMBER).subscribe(value => this.setCurrentMember(value));
    // this.currentMember = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_MEMBER)
  }

  setCurrentMember(currentMember: any): void {
    this.currentMember = currentMember;
    if (this.currentMember.imageContentType != null) {
      this.currentMemberImage = 'data:' + this.currentMember.imageContentType + ';base64,' + this.currentMember.image;
    } else if (this.currentMember.gender === 'MASCULIN') {
      this.currentMemberImage = '/content/images/avatar_male_cheikh.jpg';
    } else {
      this.currentMemberImage = '/content/images/avatar_female_hijab.jpg';
    }
  }

  // Functions used to handle the Image(defaultImage)
  byteSize(base64String: string): string {
    return this.dataUtils.byteSize(base64String);
  }

  setFileData(event: Event, field: string, isImage: boolean): void {
    this.dataUtils.loadFileToForm(event, this.newMemberForm, field, isImage).subscribe(null, (err: JhiFileLoadError) => {
      this.eventManager.broadcast(
        new JhiEventWithContent<AlertError>('jhipsterJabootApp.error', { ...err, key: 'error.file.' + err.key })
      );
    });
  }

  clearInputImage(field: string, fieldContentType: string, idInput: string): void {
    this.newMemberForm.patchValue({
      [field]: null,
      [fieldContentType]: null
    });
    if (this.elementRef && idInput && this.elementRef.nativeElement.querySelector('#' + idInput)) {
      this.elementRef.nativeElement.querySelector('#' + idInput).value = null;
    }
  }
  // ------------------------------------------------------

  chooseRelationship(relationship?: string): void {
    this.relationship = relationship ? relationship : '';
    const relationshipName: string[] = [];
    relationshipName['father'] = 'fathers';
    relationshipName['mother'] = 'mothers';
    relationshipName['husband'] = 'husbands';
    relationshipName['wife'] = 'wives';
    relationshipName['son'] = 'sons';
    relationshipName['daughter'] = 'daughters';
    if (this.relationship !== null && this.relationship !== '') {
      this.personService
        .findPossibleRelatives(this.currentTree.id, this.currentMember.id, relationshipName[relationship as string])
        .subscribe(data => {
          this.possibleRelatives = data.body;
          this.possibleRelativesHashed = hashObject(data.body as any[]);
        });
    }
    if (this.relationship === 'father' || this.relationship === 'son' || this.relationship === 'husband') {
      // this.newMemberForm.get('gender')?.setValue('MASCULIN');
      this.newMemberForm.controls['gender'].setValue('MASCULIN');
    } else {
      // this.newMemberForm.get('gender')?.setValue('FEMININ');
      this.newMemberForm.controls['gender'].setValue('FEMININ');
    }
    if (this.relationship === 'father' || this.relationship === 'mother') {
      this.newMemberForm.controls['parentOf'].setValue(this.currentMember.id);
    }
    if (this.relationship === 'son' || this.relationship === 'daughter') {
      this.newMemberForm.controls['childOf'].setValue(this.currentMember.id);
    }
    if (this.relationship === 'husband') this.newMemberForm.controls['husbandOf'].setValue(this.currentMember.id);
    if (this.relationship === 'wife') this.newMemberForm.controls['wifeOf'].setValue(this.currentMember.id);

    // ---- C'est ici que l'on doit appeler l'endpoint qui va nous donner les proches possible
    // ---- Ces proches seront mis dans this.possibleRelatives
    this.showForm = relationship !== '' ? true : false;
  }

  public onSelectRelativeChange(event: any): void {
    // event will give you full breif of action
    const newVal = event.target.value;
    if (newVal === 'null') {
      this.relativeExist = false;
      return;
    } else {
      // this.fService.getFamilyMember(newVal).subscribe(data => this.member = data);

      this.existingRelative = this.possibleRelativesHashed[newVal];
      if (this.existingRelative.avatarImg != null) {
        this.existingRelative.defaultImage = SERVER_API_URL + this.existingRelative.avatarImg;
      } else if (this.existingRelative.imageContentType != null) {
        this.existingRelative.defaultImage = 'data:' + this.existingRelative.imageContentType + ';base64,' + this.existingRelative.image;
      } else if (this.existingRelative.gender === 'MASCULIN') {
        this.existingRelative.defaultImage = '/content/images/avatar_male_cheikh.jpg';
      } else {
        this.existingRelative.defaultImage = '/content/images/avatar_female_hijab.jpg';
      }

      // console.log(this.existingRelative);
      this.relativeExist = true;
      return;
    }
  }

  save(): void {
    this.isSaving = true;
    console.log(this.newMemberForm.get(['firstName'])!.value);
    const person = this.createFromForm();
    console.log(person);
    if (person.id !== undefined && person.id != null) {
      this.subscribeToSaveResponse(this.personService.update(person));
    } else {
      this.subscribeToSaveResponse(this.personService.create(person));
    }
  }

  saveRelation(): void {
    const dateAdded = moment();
    this.isSavingRelation = true;
    let parent: IParent | undefined = undefined;
    let spouse: ISpouse | undefined = undefined;
    if (this.relationship === 'father' || this.relationship === 'mother') {
      parent = new Parent(undefined, false, dateAdded, undefined, undefined, undefined, this.existingRelative.id, this.currentMember.id);
    } else if (this.relationship === 'son' || this.relationship === 'daughter') {
      parent = new Parent(undefined, false, dateAdded, undefined, undefined, undefined, this.currentMember.id, this.existingRelative.id);
    }
    if (this.relationship === 'husband') {
      spouse = new Spouse(
        undefined,
        undefined,
        undefined,
        false,
        undefined,
        dateAdded,
        undefined,
        undefined,
        undefined,
        this.currentMember.id,
        this.existingRelative.id
      );
    }
    if (this.relationship === 'wife') {
      spouse = new Spouse(
        undefined,
        undefined,
        undefined,
        false,
        undefined,
        dateAdded,
        undefined,
        undefined,
        undefined,
        this.existingRelative.id,
        this.currentMember.id
      );
    }
    if (parent) {
      this.parentService.create(parent).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError()
      );
    }
    if (spouse) {
      this.spouseService.create(spouse).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError()
      );
    }
  }

  private createFromForm(): IPerson {
    return {
      ...new PersonWithAvatar(),
      id: this.newMemberForm.get(['id'])!.value,
      firstName: this.newMemberForm.get(['firstName'])!.value,
      lastName: this.newMemberForm.get(['lastName'])!.value,
      gender: this.newMemberForm.get(['gender'])!.value,
      dateBirth: this.newMemberForm.get(['dateBirth'])!.value
        ? moment(this.newMemberForm.get(['dateBirth'])!.value, DATE_FORMAT)
        : undefined,
      placeBirth: this.newMemberForm.get(['placeBirth'])!.value,
      dateDeath: this.newMemberForm.get(['dateDeath'])!.value
        ? moment(this.newMemberForm.get(['dateDeath'])!.value, DATE_FORMAT)
        : undefined,
      placeDeath: this.newMemberForm.get(['placeDeath'])!.value,
      defaultImageContentType: this.newMemberForm.get(['defaultImageContentType'])!.value,
      defaultImage: this.newMemberForm.get(['defaultImage'])!.value,
      parentOf: this.newMemberForm.get(['parentOf'])!.value,
      childOf: this.newMemberForm.get(['childOf'])!.value,
      husbandOf: this.newMemberForm.get(['husbandOf'])!.value,
      wifeOf: this.newMemberForm.get(['wifeOf'])!.value,
      treeId: this.currentTree.id
      // dateAdded: this.newMemberForm.get(['dateAdded'])!.value
      //   ? moment(this.newMemberForm.get(['dateAdded'])!.value, DATE_TIME_FORMAT)
      //   : undefined,
      // dateModified: this.newMemberForm.get(['dateModified'])!.value
      //   ? moment(this.newMemberForm.get(['dateModified'])!.value, DATE_TIME_FORMAT)
      //   : undefined,
      // addedById: this.newMemberForm.get(['addedById'])!.value,
      // lastUpdateById: this.newMemberForm.get(['lastUpdateById'])!.value
    };
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IPerson>>): void {
    result.subscribe(
      () => this.onSaveSuccess(),
      () => this.onSaveError()
    );
  }

  protected onSaveSuccess(): void {
    this.isSaving = false;
    this.isSavingRelation = false;
    this.afterMemberSaved.next(true);
    this.resetModal();
    // this.previousState();
  }

  protected onSaveError(): void {
    this.isSaving = false;
    this.isSavingRelation = false;
  }

  resetModal(): void {
    this.chooseRelationship('');
    this.newMemberForm = this.fb.group({
      id: [],
      firstName: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(
            "^[A-Z]([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+(([ ][A-Z]{1})?([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+)*$"
          )
        ]
      ],
      lastName: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
          Validators.pattern(
            "^[A-Z]([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+(([ ][A-Z]{1})?([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+)*$"
          )
        ]
      ],
      gender: [null, [Validators.required]],
      dateBirth: [],
      placeBirth: [],
      dateDeath: [],
      placeDeath: [],
      defaultImage: [],
      defaultImageContentType: [],
      parentOf: [],
      childOf: [],
      husbandOf: [],
      wifeOf: []
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
