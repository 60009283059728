import { Component, OnInit, ElementRef, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import * as primitives from 'basicprimitives';
import { BasicPrimitivesService } from './basic-primitives.service';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';
import { PersonService } from 'app/entities/person/person.service';
import { TreeService } from 'app/entities/tree/tree.service';
import { TreePermissionService } from 'app/entities/tree-permission/tree-permission.service';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { IPerson } from 'app/shared/model/person.model';
import { NbDialogService } from '@nebular/theme';
import { MemberIndependantNewComponent } from './modals/member-independant-new/member-independant-new.component';
import { ITreePermission } from 'app/shared/model/tree-permission.model';

@Component({
  selector: 'jhi-bp-tree',
  templateUrl: './bp-tree.component.html',
  styleUrls: ['./bp-tree.component.scss']
})
export class BpTreeComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = true;

  currentTree: any;

  items: Array<any> | null = [];

  permission: any;

  membersHashed: any = null;

  constructor(
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private _dialogService: NbDialogService,
    private _sessionSt: SessionStorageService,
    private _localSt: LocalStorageService,
    private bpService: BasicPrimitivesService,
    protected activatedRoute: ActivatedRoute,
    private personService: PersonService,
    private treeService: TreeService,
    private permissionService: TreePermissionService
  ) {}

  ngOnInit(): void {
    // On initialise le Service qui doit gérer notre Arbre
    this.bpService.initFamConfig();
    // On recupere l'arbre courant s'il existe

    // LA NOUVELLE METHODE
    this.activatedRoute.data.subscribe(({ tree }) => {
      console.log(tree);
      this.setCurrentTree(tree, tree.secret && tree.secret !== null && tree.secret !== '');
    });
    // this.items = [
    // {
    //   id: 1,
    //   // spouses: [2],
    //   title: 'Thomas Williams',
    //   // label: 'Thomas Williams',
    //   description: '1, 1st husband',
    //   image: '../images/photos/t.png',
    //   // templateName: 'myFamilyTemplate'
    // },
    // {
    //   id: 2,
    //   spouses: [],
    //   title: 'Mary Spencer',
    //   // label: 'Mary Spencer',
    //   description: '2, The Mary',
    //   image: '../assets/avatar_female_hijab.svg',
    //   // templateName: 'myFamilyTemplate'
    // },
    // ];
    // this.bpService.loadItems(this.items);
    // this.membersHashed = this.hashArrayToObject(this.items);
    // this._sessionSt.store(Constantes.SESSION_CURRENT_TREE_MEMBERS, this.membersHashed);
    // this._sessionSt.store(Constantes.SESSION_CURRENT_TREE, 'CURRENT_TREE_OBJECT');
    // this.cookieService.set(Constantes.COOKIE_TREE_PREFIX+'1', membersHashed, 1);
  }

  ngAfterViewInit(): void {
    if (this.bpService.famConfig) {
      BasicPrimitivesService.control = primitives.famdiagram.Control(
        this.el.nativeElement.getElementsByTagName('div')['basicprimitives'],
        this.bpService.famConfig as primitives.famdiagram.Config
      );
    }
    // console.log(this.el.nativeElement.getElementsByTagName('div')['basicprimitives']);
    // if (this.bpService.famConfig) {
    //   this.loading = false;
    //   BasicPrimitivesService.control = primitives.famdiagram.Control(
    //     this.el.nativeElement.getElementsByTagName('div')['basicprimitives'],
    //     this.bpService.famConfig as primitives.famdiagram.Config
    //   );
    // }
    // this.cd.detectChanges();
  }

  onNewIndependantMember(): void {
    const dialogRef = this._dialogService.open(MemberIndependantNewComponent, {
      hasScroll: true
    });
    dialogRef.componentRef.instance.afterMemberSaved.subscribe(() => {
      this.personService.findFromTree(this.currentTree.id).subscribe((res: HttpResponse<IPerson[]>) => {
        BasicPrimitivesService.updateMembers(res.body);
        BasicPrimitivesService.refreshDiagram();
      });
    });
  }

  refreshCurrentTree(): void {
    console.log(this.el.nativeElement.getElementsByTagName('div')['basicprimitives']);
    if (this.items && this.items != null) {
      this.bpService.loadItems(this.items);
      // BasicPrimitivesService.control.setOption('items', this.items);

      console.log(BasicPrimitivesService.permission);
      if (this.bpService.famConfig) {
        this.loading = false;
        console.log(BasicPrimitivesService.control.getOptions());
        BasicPrimitivesService.refreshDiagram();
        // BasicPrimitivesService.control = primitives.famdiagram.Control(
        //   this.el.nativeElement.getElementsByTagName('div')['basicprimitives'],
        //   this.bpService.famConfig as primitives.famdiagram.Config
        // );
      }
    }
    // this.cd.detectChanges();
  }

  setCurrentTree(tree: any, isSecretUrl = false): void {
    // console.log(tree);
    this.currentTree = tree;
    if (tree.secret && tree.secret !== null && tree.secret !== '')
      this._sessionSt.store(Constantes.SESSION_CURRENT_TREE_SECRET, this.currentTree.secret);
    this._sessionSt.store(Constantes.SESSION_CURRENT_TREE, this.currentTree);
    let currentUser = this._sessionSt.retrieve(Constantes.CURRENT_USER);
    if (!currentUser || currentUser === null) currentUser = this._localSt.retrieve(Constantes.CURRENT_USER);
    console.log(currentUser);
    if (currentUser && currentUser !== null) {
      if (currentUser.login === this.currentTree.ownerLogin) {
        this.setCurrentTreePermission(BasicPrimitivesService.setAdminPermission());
      } else {
        this.permissionService.findPersonalPermissionOnTree(this.currentTree.id).subscribe(
          (res: HttpResponse<ITreePermission>) => {
            if (res.body !== null) BasicPrimitivesService.updatePermission(res.body);
            this.setCurrentTreePermission(res.body);
          },
          () => {
            this.setCurrentTreePermission(BasicPrimitivesService.setPublicPermission());
          }
        );
      }
    } else {
      this.setCurrentTreePermission(BasicPrimitivesService.setPublicPermission());
    }

    if (!isSecretUrl) {
      this.personService
        .findFromTree(this.currentTree.id)
        .subscribe((res: HttpResponse<IPerson[]>) => this.setCurrentTreeMembers(res.body));
    } else
      this.personService
        .findFromTreeSecret(this.currentTree.id, this.currentTree.secret)
        .subscribe((res: HttpResponse<IPerson[]>) => this.setCurrentTreeMembers(res.body));
  }

  setCurrentTreeMembers(data: IPerson[] | null): void {
    this.items = data;
    const itemsHashed = this.personService.hashPersonFromServer(this.items as IPerson[]);
    this._sessionSt.store(Constantes.SESSION_CURRENT_TREE_MEMBERS, itemsHashed);
    BasicPrimitivesService.updateMembers(this.items);
    this.refreshCurrentTree();
  }

  setCurrentTreePermission(data: ITreePermission | null): void {
    if (data === null) {
      this._sessionSt.clear(Constantes.SESSION_CURRENT_TREE_PERMISSION);
      return;
    }
    this.permission = data;
    this._sessionSt.store(Constantes.SESSION_CURRENT_TREE_PERMISSION, this.permission);
    // BasicPrimitivesService.updatePermission(this.permission);
  }

  // Every element of the array must contains an id
  hashArrayToObject(data: Array<any>): any {
    const object = {};
    data.map(element => {
      object[element.id] = element;
    });
    return object;
  }

  ngOnDestroy(): void {
    console.log('ON DESTROY CALLED IN BPTREECOMPONENT');
    /*
    this._sessionSt.clear(Constantes.SESSION_CURRENT_TREE);
    this._sessionSt.clear(Constantes.SESSION_CURRENT_TREE_MEMBERS);
    this._sessionSt.clear(Constantes.SESSION_CURRENT_TREE_PERMISSION);
    this._sessionSt.clear(Constantes.SESSION_CURRENT_MEMBER);
    */
  }
}
