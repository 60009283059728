import { NgModule } from '@angular/core';
import { BpTreeComponent } from './bp-tree.component';
import { BasicPrimitivesService } from './basic-primitives.service';
import { JhipsterJabootSharedModule } from 'app/shared/shared.module';
import { NbCardModule, NbSpinnerModule, NbButtonModule, NbInputModule, NbAlertModule, NbToastrModule } from '@nebular/theme';
import { ModalsModule } from './modals/modals.module';
import { RouterModule } from '@angular/router';
import { BP_TREE_ROUTE } from './bp-tree.route';

@NgModule({
  declarations: [BpTreeComponent],
  imports: [
    JhipsterJabootSharedModule,
    RouterModule.forChild(BP_TREE_ROUTE),
    ModalsModule,
    // NgxWebstorageModule.forRoot({ prefix: 'bpTree', separator: '-', caseSensitive:true }),
    NbCardModule,
    NbSpinnerModule,
    NbButtonModule,
    NbAlertModule,
    NbInputModule,
    NbToastrModule.forRoot({ duration: 3000 })
  ],
  exports: [BpTreeComponent],
  providers: [BasicPrimitivesService]
})
export class BpTreeModule {}
