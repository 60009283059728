import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { SessionStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';
import { SERVER_API_URL } from 'app/app.constants';

@Component({
  selector: 'jhi-member-infos',
  templateUrl: './member-infos.component.html',
  styleUrls: ['./member-infos.component.scss']
})
export class MemberInfosComponent implements OnInit {
  @Output()
  afterAction = new EventEmitter<{
    action: string;
    object: any;
  }>();

  currentTree: any = null;

  currentTreeSecret: any = null;

  currentMember: any = null;

  currentMemberImage: any = '';

  modalLocation = 'personal';

  constructor(protected dialogRef: NbDialogRef<any>, private _sessionSt: SessionStorageService) {}

  ngOnInit(): void {
    this.currentMember = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_MEMBER);
    this.currentTree = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE);
    if (this.currentMember.avatarImg != null) {
      this.currentMemberImage = SERVER_API_URL + this.currentMember.avatarImg;
    } else if (this.currentMember.imageContentType != null) {
      this.currentMemberImage = 'data:' + this.currentMember.imageContentType + ';base64,' + this.currentMember.image;
    } else if (this.currentMember.gender === 'MASCULIN') {
      this.currentMemberImage = '/content/images/avatar_male_cheikh.jpg';
    } else {
      this.currentMemberImage = '/content/images/avatar_female_hijab.jpg';
    }

    this._sessionSt.observe(Constantes.SESSION_CURRENT_TREE).subscribe(value => (this.currentTree = value));
    this._sessionSt.observe(Constantes.SESSION_CURRENT_MEMBER).subscribe(value => this.setCurrentMember(value));
  }

  changeTab(tabName: string): void {
    this.modalLocation = tabName;
  }

  close(): void {
    this.dialogRef.close();
  }

  setCurrentMember(currentMember: any): void {
    this.currentMember = currentMember;
    if (this.currentMember.avatarImg != null) {
      this.currentMemberImage = this.currentMember.avatarImg;
    } else if (this.currentMember.imageContentType != null) {
      this.currentMemberImage = 'data:' + this.currentMember.imageContentType + ';base64,' + this.currentMember.image;
    } else if (this.currentMember.gender === 'MASCULIN') {
      this.currentMemberImage = Constantes.MEMBER_DEFAULT_IMAGE_MALE;
    } else {
      this.currentMemberImage = Constantes.MEMBER_DEFAULT_IMAGE_FEMALE;
    }
  }

  onAfterAction(event: { action: string; object: any }): void {
    console.log(event);
    this.afterAction.next(event);
  }
}
