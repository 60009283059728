import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import { TreeService } from 'app/entities/tree/tree.service';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { HttpResponse } from '@angular/common/http';
import { ITree } from 'app/shared/model/tree.model';
moment.locale('fr');

@Component({
  selector: 'jhi-public-trees',
  templateUrl: './public-trees.component.html',
  styleUrls: ['./public-trees.component.scss']
})
export class PublicTreesComponent implements OnInit {
  arbres: any = [];

  inputSearch = '';

  filter = 'LASTUPDATE';

  searchOn: any[] = [];

  moment: any;

  constructor(protected treeService: TreeService, protected _sessionSt: SessionStorageService, protected _localSt: LocalStorageService) {}

  ngOnInit(): void {
    this.moment = moment;
    this.searchOn['name'] = true;
    this.searchOn['owner'] = false;
    this.loadAllPublicTrees();
  }

  loadAllPublicTrees(): void {
    // this.treeService
    //   .query({
    //     'visibility.equals': 'PUBLIC'
    //   })
    //   .subscribe((res: HttpResponse<ITree[]>) => (this.arbres = res.body));
    const req = {};
    if (this.inputSearch !== null && this.inputSearch !== '') {
      if (this.searchOn['owner']) {
        if (this.searchOn['name']) {
          const splitInputSearch = this.inputSearch.split('/');
          if (splitInputSearch.length > 1) {
            req['ownerLogin.contains'] = splitInputSearch[0];
            req['code.contains'] = splitInputSearch[1];
          } else {
            req['code.contains'] = splitInputSearch[0];
          }
        } else {
          req['ownerLogin.contains'] = this.inputSearch;
        }
      } else {
        if (this.searchOn['name']) {
          req['code.contains'] = this.inputSearch;
        }
      }
    }
    req['filterBy.equals'] = this.filter;
    this.treeService.findPublic(req).subscribe((res: HttpResponse<ITree[]>) => (this.arbres = res.body));
  }

  switch(newValue: string, searchOrFilter = 'searchOn'): void {
    if (searchOrFilter === 'searchOn') {
      this.searchOn[newValue] = !this.searchOn[newValue];
    } else {
      this.filter = newValue;
    }
  }
}
