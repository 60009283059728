import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import './vendor';
import { JhipsterJabootSharedModule } from 'app/shared/shared.module';
import { JhipsterJabootCoreModule } from 'app/core/core.module';
import { JhipsterJabootAppRoutingModule } from './app-routing.module';
import { JhipsterJabootHomeModule } from './home/home.module';
import { JhipsterJabootEntityModule } from './entities/entity.module';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { MainComponent } from './layouts/main/main.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { PageRibbonComponent } from './layouts/profiles/page-ribbon.component';
import { ActiveMenuDirective } from './layouts/navbar/active-menu.directive';
import { ErrorComponent } from './layouts/error/error.component';
import { NbThemeModule, NbToastrModule } from '@nebular/theme';
import { PublicTreesModule } from './public-trees/public-trees.module';
import { BpTreeModule } from './bp-tree/bp-tree.module';
import { PersonalTreesModule } from './personal-trees/personal-trees.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';

@NgModule({
  imports: [
    BrowserModule,
    JhipsterJabootSharedModule,
    NbThemeModule.forRoot(),
    NbToastrModule.forRoot({ duration: 3000 }),
    NbEvaIconsModule,
    JhipsterJabootCoreModule,
    JhipsterJabootHomeModule,
    PublicTreesModule,
    PersonalTreesModule,
    BpTreeModule,
    // jhipster-needle-angular-add-module JHipster will add new module here
    JhipsterJabootEntityModule,
    JhipsterJabootAppRoutingModule
  ],
  declarations: [MainComponent, NavbarComponent, ErrorComponent, PageRibbonComponent, ActiveMenuDirective, FooterComponent],
  bootstrap: [MainComponent]
})
export class JhipsterJabootAppModule {}
