import { Moment } from 'moment';
import { ITreeView } from 'app/shared/model/tree-view.model';
import { ITreePopularity } from 'app/shared/model/tree-popularity.model';
import { ITreePermission } from 'app/shared/model/tree-permission.model';
import { ITreePhoto } from 'app/shared/model/tree-photo.model';
import { IPersonTree } from 'app/shared/model/person-tree.model';
import { TreeVisibility } from 'app/shared/model/enumerations/tree-visibility.model';

export interface ITree {
  id?: number;
  name?: string;
  code?: string;
  description?: string;
  visibility?: TreeVisibility;
  secret?: string;
  dateAdded?: Moment;
  dateModified?: Moment;
  treeViews?: ITreeView[];
  treePopularities?: ITreePopularity[];
  treePermissions?: ITreePermission[];
  treePhotos?: ITreePhoto[];
  personTrees?: IPersonTree[];
  ownerLogin?: string;
  ownerId?: number;
}

export class Tree implements ITree {
  constructor(
    public id?: number,
    public name?: string,
    public code?: string,
    public description?: string,
    public visibility?: TreeVisibility,
    public secret?: string,
    public dateAdded?: Moment,
    public dateModified?: Moment,
    public treeViews?: ITreeView[],
    public treePopularities?: ITreePopularity[],
    public treePermissions?: ITreePermission[],
    public treePhotos?: ITreePhoto[],
    public personTrees?: IPersonTree[],
    public ownerLogin?: string,
    public ownerId?: number
  ) {}
}
