import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { ITreePermission } from 'app/shared/model/tree-permission.model';

type EntityResponseType = HttpResponse<ITreePermission>;
type EntityArrayResponseType = HttpResponse<ITreePermission[]>;

@Injectable({ providedIn: 'root' })
export class TreePermissionService {
  public resourceUrl = SERVER_API_URL + 'api/tree-permissions';

  constructor(protected http: HttpClient) {}

  create(treePermission: ITreePermission): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(treePermission);
    return this.http
      .post<ITreePermission>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(treePermission: ITreePermission): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(treePermission);
    return this.http
      .put<ITreePermission>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ITreePermission>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ITreePermission[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findAllPermissionsOnTree(idTree: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<ITreePermission[]>(`${this.resourceUrl}/on_tree/${idTree}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findPersonalPermissionOnTree(idTree: number): Observable<EntityResponseType> {
    return this.http
      .get<ITreePermission>(`${this.resourceUrl}/personal/on_tree/${idTree}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(treePermission: ITreePermission): ITreePermission {
    const copy: ITreePermission = Object.assign({}, treePermission, {
      dateAdded: treePermission.dateAdded && treePermission.dateAdded.isValid() ? treePermission.dateAdded.toJSON() : undefined,
      dateModified: treePermission.dateModified && treePermission.dateModified.isValid() ? treePermission.dateModified.toJSON() : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateAdded = res.body.dateAdded ? moment(res.body.dateAdded) : undefined;
      res.body.dateModified = res.body.dateModified ? moment(res.body.dateModified) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((treePermission: ITreePermission) => {
        treePermission.dateAdded = treePermission.dateAdded ? moment(treePermission.dateAdded) : undefined;
        treePermission.dateModified = treePermission.dateModified ? moment(treePermission.dateModified) : undefined;
      });
    }
    return res;
  }
}
