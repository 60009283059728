import { Moment } from 'moment';

export interface IParent {
  id?: number;
  adopted?: boolean;
  dateAdded?: Moment;
  dateModified?: Moment;
  addedByLogin?: string;
  addedById?: number;
  parentId?: number;
  childId?: number;
}

export class Parent implements IParent {
  constructor(
    public id?: number,
    public adopted?: boolean,
    public dateAdded?: Moment,
    public dateModified?: Moment,
    public addedByLogin?: string,
    public addedById?: number,
    public parentId?: number,
    public childId?: number
  ) {
    this.adopted = this.adopted || false;
  }
}
