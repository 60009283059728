import { NgModule } from '@angular/core';
import { MemberAddComponent } from './member-add/member-add.component';
import { MemberRemoveComponent } from './member-remove/member-remove.component';
import { JhipsterJabootSharedModule } from 'app/shared/shared.module';
import {
  NbDialogModule,
  NbCardModule,
  NbButtonModule,
  NbInputModule,
  NbDatepickerModule,
  NbSelectModule,
  NbSpinnerModule
} from '@nebular/theme';
import { MemberInfosModule } from './member-infos/member-infos.module';
import { MemberIndependantNewComponent } from './member-independant-new/member-independant-new.component';
import { NbMomentDateModule } from '@nebular/moment';

@NgModule({
  declarations: [MemberAddComponent, MemberRemoveComponent, MemberIndependantNewComponent],
  imports: [
    JhipsterJabootSharedModule,
    MemberInfosModule,
    NbDialogModule.forChild(),
    NbCardModule,
    NbInputModule,
    NbSelectModule,
    NbSpinnerModule,
    NbDatepickerModule,
    NbMomentDateModule,
    NbButtonModule
  ],
  entryComponents: [MemberAddComponent, MemberRemoveComponent, MemberIndependantNewComponent]
})
export class ModalsModule {}
