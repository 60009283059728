import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { PersonalTreesComponent } from './personal-trees.component';
import { PersonalTreesResolve } from './personal-trees.resolve';
import { Routes } from '@angular/router';
import { PersonalTreeNewComponent } from './personal-tree-new/personal-tree-new.component';
import { PersonalTreeNewResolve } from './personal-tree-new.resolve';

export const personalTreesRoute: Routes = [
  {
    path: ':userLogin/trees',
    component: PersonalTreesComponent,
    resolve: {
      personalTrees: PersonalTreesResolve
    },
    data: {
      authorities: ['ROLE_USER'],
      pageTitle: 'jhipsterJabootApp.person.home.title'
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: ':ownerLogin/trees/new',
    component: PersonalTreeNewComponent,
    resolve: {
      owner: PersonalTreeNewResolve
    },
    data: {
      authorities: ['ROLE_USER'],
      pageTitle: 'jhipsterJabootApp.person.home.title'
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: ':ownerLogin/tree/:codeTree/details',
    loadChildren: () => import('./personal-tree-details/personal-tree-details.module').then(m => m.PersonalTreeDetailsModule)
  }
];
