import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { SessionStorageService } from 'ngx-webstorage';
import { NbDialogRef, NbDateService } from '@nebular/theme';
import { JhiDataUtils, JhiEventManager, JhiEventWithContent, JhiFileLoadError } from 'ng-jhipster';
import { PersonService } from 'app/entities/person/person.service';
import * as moment from 'moment';
import { Constantes } from 'app/shared/constants.const';
import { AlertError } from 'app/shared/alert/alert-error.model';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IPerson, PersonWithAvatar } from 'app/shared/model/person.model';
import { DATE_FORMAT } from 'app/shared/constants/input.constants';

@Component({
  selector: 'jhi-member-independant-new',
  templateUrl: './member-independant-new.component.html',
  styleUrls: ['./member-independant-new.component.scss']
})
export class MemberIndependantNewComponent implements OnInit {
  @Output()
  afterMemberSaved = new EventEmitter<any>();

  currentTree: any = null;

  // La valeur maximale qu'il est possible d'affecter a une date
  dateMax: Date;

  // Permet de savoir si l'utilisateur a valider le formulaire ou pas
  isSaving = false;

  // person = new FamilyMember();

  newMemberForm = this.fb.group({
    id: [],
    firstName: [
      null,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
        Validators.pattern(
          "^[A-Z]([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+(([ ][A-Z]{1})?([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+)*$"
        )
      ]
    ],
    lastName: [
      null,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(25),
        Validators.pattern(
          "^[A-Z]([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+(([ ][A-Z]{1})?([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+)*$"
        )
      ]
    ],
    gender: ['MASCULIN'],
    dateBirth: [],
    placeBirth: [],
    dateDeath: [],
    placeDeath: [],
    defaultImage: [],
    defaultImageContentType: []
    // dateAdded: [],
    // dateModified: [],
    // addedById: [null, Validators.required],
    // lastUpdateById: []
  });

  constructor(
    protected dialogRef: NbDialogRef<any>,
    protected elementRef: ElementRef,
    protected _sessionSt: SessionStorageService,
    private fb: FormBuilder,
    protected dataUtils: JhiDataUtils,
    protected eventManager: JhiEventManager,
    protected personService: PersonService,
    protected dateService: NbDateService<Date>
  ) {
    this.dateMax = this.dateService.today();
  }

  ngOnInit(): void {
    this.currentTree = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE);

    this._sessionSt.observe(Constantes.SESSION_CURRENT_TREE).subscribe(value => (this.currentTree = value));
  }

  // Functions used to handle the Image(defaultImage)
  byteSize(base64String: string): string {
    return this.dataUtils.byteSize(base64String);
  }

  setFileData(event: Event, field: string, isImage: boolean): void {
    this.dataUtils.loadFileToForm(event, this.newMemberForm, field, isImage).subscribe(null, (err: JhiFileLoadError) => {
      this.eventManager.broadcast(
        new JhiEventWithContent<AlertError>('jhipsterJabootApp.error', { ...err, key: 'error.file.' + err.key })
      );
    });
  }

  clearInputImage(field: string, fieldContentType: string, idInput: string): void {
    this.newMemberForm.patchValue({
      [field]: null,
      [fieldContentType]: null
    });
    if (this.elementRef && idInput && this.elementRef.nativeElement.querySelector('#' + idInput)) {
      this.elementRef.nativeElement.querySelector('#' + idInput).value = null;
    }
  }

  // Handling submit of the form

  save(): void {
    this.isSaving = true;
    const person = this.createFromForm();
    if (person.id !== undefined && person.id != null) {
      this.subscribeToSaveResponse(this.personService.update(person));
    } else {
      this.subscribeToSaveResponse(this.personService.create(person));
    }
  }

  private createFromForm(): IPerson {
    return {
      ...new PersonWithAvatar(),
      id: this.newMemberForm.get(['id'])!.value,
      firstName: this.newMemberForm.get(['firstName'])!.value,
      lastName: this.newMemberForm.get(['lastName'])!.value,
      gender: this.newMemberForm.get(['gender'])!.value,
      dateBirth: this.newMemberForm.get(['dateBirth'])!.value
        ? moment(this.newMemberForm.get(['dateBirth'])!.value, DATE_FORMAT)
        : undefined,
      placeBirth: this.newMemberForm.get(['placeBirth'])!.value,
      dateDeath: this.newMemberForm.get(['dateDeath'])!.value
        ? moment(this.newMemberForm.get(['dateDeath'])!.value, DATE_FORMAT)
        : undefined,
      placeDeath: this.newMemberForm.get(['placeDeath'])!.value,
      defaultImageContentType: this.newMemberForm.get(['defaultImageContentType'])!.value,
      defaultImage: this.newMemberForm.get(['defaultImage'])!.value,
      treeId: this.currentTree.id
    };
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IPerson>>): void {
    result.subscribe(
      () => this.onSaveSuccess(),
      () => this.onSaveError()
    );
  }

  protected onSaveSuccess(): void {
    this.isSaving = false;
    this.afterMemberSaved.next(true);
    this.close();
    // this.previousState();
  }

  protected onSaveError(): void {
    this.isSaving = false;
  }

  close(): void {
    this.dialogRef.close();
  }
}
