import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { ISpouse } from 'app/shared/model/spouse.model';

type EntityResponseType = HttpResponse<ISpouse>;
type EntityArrayResponseType = HttpResponse<ISpouse[]>;

@Injectable({ providedIn: 'root' })
export class SpouseService {
  public resourceUrl = SERVER_API_URL + 'api/spouses';

  constructor(protected http: HttpClient) {}

  create(spouse: ISpouse): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(spouse);
    return this.http
      .post<ISpouse>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(spouse: ISpouse): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(spouse);
    return this.http
      .put<ISpouse>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ISpouse>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ISpouse[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  deleteRelation(relation: { husbandId: number; wifeId: number }): Observable<HttpResponse<{}>> {
    return this.http.post(`${this.resourceUrl}/delete`, relation, { observe: 'response' });
  }

  protected convertDateFromClient(spouse: ISpouse): ISpouse {
    const copy: ISpouse = Object.assign({}, spouse, {
      weddingDate: spouse.weddingDate && spouse.weddingDate.isValid() ? spouse.weddingDate.toJSON() : undefined,
      dateDivorce: spouse.dateDivorce && spouse.dateDivorce.isValid() ? spouse.dateDivorce.toJSON() : undefined,
      dateAdded: spouse.dateAdded && spouse.dateAdded.isValid() ? spouse.dateAdded.toJSON() : undefined,
      dateModified: spouse.dateModified && spouse.dateModified.isValid() ? spouse.dateModified.toJSON() : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.weddingDate = res.body.weddingDate ? moment(res.body.weddingDate) : undefined;
      res.body.dateDivorce = res.body.dateDivorce ? moment(res.body.dateDivorce) : undefined;
      res.body.dateAdded = res.body.dateAdded ? moment(res.body.dateAdded) : undefined;
      res.body.dateModified = res.body.dateModified ? moment(res.body.dateModified) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((spouse: ISpouse) => {
        spouse.weddingDate = spouse.weddingDate ? moment(spouse.weddingDate) : undefined;
        spouse.dateDivorce = spouse.dateDivorce ? moment(spouse.dateDivorce) : undefined;
        spouse.dateAdded = spouse.dateAdded ? moment(spouse.dateAdded) : undefined;
        spouse.dateModified = spouse.dateModified ? moment(spouse.dateModified) : undefined;
      });
    }
    return res;
  }
}
