import { Injectable } from '@angular/core';
import { FamConfig } from './models/fam-config.model';
import { common, famdiagram, orgdiagram, text } from 'basicprimitives';
import { OrgConfig } from './models/org-config.model';
import { NbDialogService, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { MemberInfosComponent } from './modals/member-infos/member-infos.component';
import { MemberAddComponent } from './modals/member-add/member-add.component';
import { MemberRemoveComponent } from './modals/member-remove/member-remove.component';
import { SessionStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';
import { PersonService } from 'app/entities/person/person.service';
import { HttpResponse } from '@angular/common/http';
import { IPerson } from 'app/shared/model/person.model';
import { ITreePermission } from 'app/shared/model/tree-permission.model';
import { SERVER_API_URL } from 'app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class BasicPrimitivesService {
  static control?: orgdiagram.BaseControl | any;

  static dialogService: NbDialogService;

  static sessionSt: SessionStorageService;

  static personService: PersonService;

  static currentTree: any = null;

  static members: any[] | null;

  static permission: ITreePermission = {
    addMember: false,
    updateMember: false,
    deleteMember: false,
    addPhoto: false,
    deletePhoto: false,
    addTestimony: false,
    updateTestimony: false,
    deleteTestimony: false
  };

  static toastrService: NbToastrService;

  famConfig?: FamConfig | famdiagram.Config;

  orgConfig?: OrgConfig | orgdiagram.Config;

  accessControl = {
    // read: true,
    // write: true,
    // delete: true,
    addMember: false,
    updateMember: false,
    deleteMember: false,
    addPhoto: false,
    deletePhoto: false,
    addTestimony: false,
    updateTestimony: false,
    deleteTestimony: false
  };

  constructor(
    private _sessionSt: SessionStorageService,
    private _dialogService: NbDialogService,
    private personService: PersonService,
    private toastrService: NbToastrService
  ) {
    BasicPrimitivesService.dialogService = _dialogService;
    BasicPrimitivesService.sessionSt = _sessionSt;
    BasicPrimitivesService.personService = personService;
    BasicPrimitivesService.toastrService = toastrService;
  }

  static refreshDiagram(): void {
    // BasicPrimitivesService.control.setOption('items', BasicPrimitivesService.members);
    BasicPrimitivesService.control.setOption('items', BasicPrimitivesService.members);
    BasicPrimitivesService.updateButtons();
    BasicPrimitivesService.control.update(orgdiagram.UpdateMode.Refresh);
  }

  static updateMembers(items: Array<any> | null): void {
    const itemsHashed = BasicPrimitivesService.personService.hashPersonFromServer(items as Array<any>);
    BasicPrimitivesService.sessionSt.store(Constantes.SESSION_CURRENT_TREE_MEMBERS, itemsHashed);
    BasicPrimitivesService.members = items;
    // BasicPrimitivesService.control.setOption('items', items);
  }

  static updateButtons(): void {
    const buttons = [];
    // console.log(this.accessControl);
    if (BasicPrimitivesService.permission.deleteMember) {
      buttons.push(new famdiagram.ButtonConfig('delete', 'ui-icon-close', 'Delete'));
    }
    // if (this.accessControl.read) {
    buttons.push(new famdiagram.ButtonConfig('properties', 'ui-icon-gear', 'Info'));
    // }
    if (BasicPrimitivesService.permission.addMember) {
      buttons.push(new famdiagram.ButtonConfig('add', 'ui-icon-person', 'Add'));
    }
    BasicPrimitivesService.control.setOption('buttons', buttons);
  }

  static updatePermission(permission: ITreePermission): void {
    BasicPrimitivesService.permission = permission;
    // BasicPrimitivesService.updateButtons();
    // BasicPrimitivesService.control.update(orgdiagram.UpdateMode.Refresh);
  }

  static setAdminPermission(): ITreePermission {
    BasicPrimitivesService.permission = {
      addMember: true,
      updateMember: true,
      deleteMember: true,
      addPhoto: true,
      deletePhoto: true,
      addTestimony: true,
      updateTestimony: true,
      deleteTestimony: true
    };
    return BasicPrimitivesService.permission;
  }

  static setPublicPermission(): ITreePermission {
    BasicPrimitivesService.permission = {
      addMember: false,
      updateMember: false,
      deleteMember: false,
      addPhoto: false,
      deletePhoto: false,
      addTestimony: false,
      updateTestimony: false,
      deleteTestimony: false
    };
    return BasicPrimitivesService.permission;
  }

  static updateHighlightPathAnnotations(data?: any): any {
    const annotations = BasicPrimitivesService.control.getOption('annotations');
    const newAnnotations = [];
    let index;
    let len;
    for (index = 0, len = annotations.length; index < len; index += 1) {
      const annotation = annotations[index];
      if (annotation.annotationType !== common.AnnotationType.HighlightPath) {
        newAnnotations.push(annotation);
      }
    }

    let items: Array<any> = [];
    if (data.context) {
      BasicPrimitivesService.toastrService.default('', data.context!.title, {
        position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
        limit: 1,
        hasIcon: true,
        icon: 'person'
      });
    }

    items = items.concat(data.parentItems);
    items = items.concat(data.childrenItems);
    for (index = 0, len = items.length; index < len; index += 1) {
      const parent = items[index];
      const highlightid = data.context.id;
      const annotation = new famdiagram.HighlightPathAnnotationConfig({
        items: [highlightid, parent.id],
        color: common.Colors.Navy,
        opacity: 0.2,
        lineWidth: 16,
        zOrderType: common.ZOrderType.Background,
        showArrows: false
      });
      newAnnotations.push(annotation);
    }
    // FamilyBasicPrimitivesService.control.setOptions({ annotations: newAnnotations });
    BasicPrimitivesService.control.setOptions({ annotations: newAnnotations });
  }

  initOrgConfig(orgConfig?: OrgConfig | orgdiagram.Config): OrgConfig | orgdiagram.Config {
    if (orgConfig) {
      this.orgConfig = orgConfig;
      return this.orgConfig;
    }
    this.orgConfig = new OrgConfig();
    return this.orgConfig;
  }

  initFamConfig(famConfig?: FamConfig | famdiagram.Config): FamConfig | famdiagram.Config {
    if (famConfig) {
      this.famConfig = famConfig;
      return this.famConfig;
    }
    this.famConfig = new FamConfig();
    /* Family Diagram Specific Options */
    this.famConfig.neighboursSelectionMode = common.NeighboursSelectionMode.ParentsChildrenSiblingsAndSpouses;
    this.famConfig.groupByType = common.GroupByType.Parents;
    this.famConfig.alignBylevels = true;
    this.famConfig.hideGrandParentsConnectors = false;
    this.famConfig.enableMatrixLayout = false;
    this.famConfig.minimumMatrixSize = 2;
    this.famConfig.maximumColumnsInMatrix = 6;

    /* On-screen annotations specific options */
    const annotationOptions = {
      connectorPlacementType: common.ConnectorPlacementType.Offbeat,
      connectorShapeType: common.ConnectorShapeType.OneWay,
      labelPlacementType: common.ConnectorLabelPlacementType.Between,
      lineWidth: 2,
      lineType: common.LineType.Dashed,
      color: common.Colors.Red,
      offset: 5,
      zOrderType: common.ZOrderType.Auto
    };
    this.famConfig.annotations = [];
    this.famConfig.annotations[0] = annotationOptions;

    /* Layout */
    this.famConfig.pageFitMode = common.PageFitMode.FitToPage;
    this.famConfig.orientationType = common.OrientationType.Top;
    this.famConfig.verticalAlignment = common.VerticalAlignmentType.Middle;
    this.famConfig.maximumColumnsInMatrix = 6;
    this.famConfig.minimalVisibility = common.Visibility.Dot;
    this.famConfig.selectionPathMode = common.SelectionPathMode.None;

    const buttons = [];
    if (this.accessControl.deleteMember) {
      buttons.push(new famdiagram.ButtonConfig('delete', 'ui-icon-close', 'Delete'));
    }
    // if (this.accessControl.read) {
    buttons.push(new famdiagram.ButtonConfig('properties', 'ui-icon-gear', 'Info'));
    // }
    if (this.accessControl.addMember) {
      buttons.push(new famdiagram.ButtonConfig('add', 'ui-icon-person', 'Add'));
    }
    this.famConfig.buttons = buttons;
    this.famConfig.hasButtons = common.Enabled.Auto;
    this.famConfig.hasSelectorCheckbox = common.Enabled.Auto;
    this.famConfig.selectCheckBoxLabel = 'Selected';
    this.famConfig.itemTitleFirstFontColor = common.Colors.White;
    this.famConfig.itemTitleSecondFontColor = common.Colors.White;
    this.famConfig.buttonsPanelSize = 28;
    this.famConfig.groupTitlePanelSize = 24;
    this.famConfig.checkBoxPanelSize = 24;

    /* group title options */
    this.famConfig.groupTitlePlacementType = common.AdviserPlacementType.Left;
    this.famConfig.groupTitleOrientation = text.TextOrientationType.RotateRight;
    this.famConfig.groupTitleVerticalAlignment = common.VerticalAlignmentType.Middle;
    this.famConfig.groupTitleHorizontalAlignment = common.HorizontalAlignmentType.Center;
    this.famConfig.groupTitleFontSize = '12px';
    this.famConfig.groupTitleFontFamily = 'Arial';
    this.famConfig.groupTitleColor = common.Colors.RoyalBlue;
    this.famConfig.groupTitleFontWeight = 'normal';
    this.famConfig.groupTitleFontStyle = 'normal';

    /* Template */
    // const defaultTemplateOptions = {
    //   minimizedItemCornerRadius: 8,
    //   minimizedItemSize: new common.Size(16, 16),
    //   highlightPadding: 4,
    //   minimizedItemShapeType: common.ShapeType.None,
    //   minimizedItemLineWidth: 1,
    //   minimizedItemLineType: common.LineType.Solid,
    //   minimizedItemBorderColor: null,
    //   minimizedItemFillColor: null,
    //   minimizedItemOpacity: 1.0
    // };

    this.famConfig.templates = [this.getFamilyTemplate()];
    this.famConfig.defaultTemplateName = 'myFamilyTemplate';

    /* Intervals */
    this.famConfig.normalLevelShift = 20;
    this.famConfig.dotLevelShift = 20;
    this.famConfig.lineLevelShift = 20;

    this.famConfig.normalItemsInterval = 20;
    this.famConfig.dotItemsInterval = 10;
    this.famConfig.lineItemsInterval = 10;

    this.famConfig.cousinsIntervalMultiplier = 0;

    /* Connectors */
    this.famConfig.arrowsDirection = common.GroupByType.Parents;
    this.famConfig.showExtraArrows = true;
    this.famConfig.extraArrowsMinimumSpace = 30;
    this.famConfig.elbowType = common.ElbowType.Round;
    this.famConfig.bevelSize = 4;
    this.famConfig.elbowDotSize = 4;
    this.famConfig.linesType = common.LineType.Solid;
    this.famConfig.linesColor = common.Colors.Black;
    this.famConfig.linesWidth = 1;

    this.famConfig.showLabels = common.Enabled.Auto;
    /* Labels */
    this.famConfig.labelSize = new common.Size(60, 40);
    this.famConfig.labelOrientation = text.TextOrientationType.Horizontal;
    this.famConfig.labelPlacement = common.PlacementType.Top;
    this.famConfig.labelOffset = 1;
    this.famConfig.labelFontSize = '10px';
    this.famConfig.labelFontFamily = 'Arial';
    this.famConfig.labelColor = common.Colors.Black;
    this.famConfig.labelFontWeight = 'normal';
    this.famConfig.labelFontStyle = 'normal';

    /* Callout */
    this.famConfig.calloutMaximumVisibility = common.Visibility.Dot;
    this.famConfig.showCallout = true;
    this.famConfig.calloutPlacementOffset = 100;
    this.famConfig.calloutfillColor = '#000000';
    this.famConfig.calloutBorderColor = null;
    this.famConfig.calloutOffset = 4;
    this.famConfig.calloutCornerRadius = 4;
    this.famConfig.calloutPointerWidth = '10%';
    this.famConfig.calloutLineWidth = 1;
    this.famConfig.calloutOpacity = 0.2;

    /* Interactivity */
    this.famConfig.navigationMode = common.NavigationMode.Default;
    this.famConfig.highlightGravityRadius = 40;
    this.famConfig.enablePanning = true;

    /* Graphics */
    (this.famConfig.graphicsType = common.GraphicsType.SVG), (this.famConfig.scale = 1.0);

    common.mergeObjects(this.famConfig, {
      onButtonClick: this.onButtonClick,
      // onCursorChanging: onCursorChanging,
      // onCursorChanged: onCursorChanged,
      onHighlightChanging: this.onHighlightChanging,
      // onHighlightChanged: onHighlightChanged,
      // onSelectionChanged: onSelectionChanged
      onItemRender: this.onItemRender
    });

    return this.famConfig;
  }

  loadItems(items: Array<any> | null): void {
    if (this.famConfig && this.famConfig != null && items != null) {
      this.famConfig.items = items;
    } else if (this.orgConfig && this.orgConfig != null && items != null) {
      this.orgConfig.items = items;
    }
  }

  // -----------------------------------FUNCTIONS TO HANDLE A FAMDIAGRAM-------------------------------------

  // Update the buttons because the access control changed
  updateButtonsAC(): void {
    const buttons = [];
    // console.log(this.accessControl);
    if (this.accessControl.deleteMember) {
      buttons.push(new famdiagram.ButtonConfig('delete', 'ui-icon-close', 'Delete'));
    }
    // if (this.accessControl.read) {
    buttons.push(new famdiagram.ButtonConfig('properties', 'ui-icon-gear', 'Info'));
    // }
    if (this.accessControl.addMember) {
      buttons.push(new famdiagram.ButtonConfig('add', 'ui-icon-person', 'Add'));
    }
    if (this.famConfig) this.famConfig.buttons = buttons;
    // console.log(this.famConfig.buttons);
  }

  onHighlightChanging(e?: any, data?: any): any {
    BasicPrimitivesService.updateHighlightPathAnnotations(data);
  }

  /*
  onHighlightChanged(e?: any, data?: any): any {
  }
  onCursorChanging(e?: any, data?: any): any {
  }
  onCursorChanged(e?: any, data?: any): any {
  }
  onSelectionChanging(e?: any, data?: any): any {
  }
  onSelectionChanged(e?: any, data?: any): any {
  }
  */

  onButtonClick(e?: any, data?: any): any {
    // console.log(e);
    // console.log(data);
    BasicPrimitivesService.sessionSt.store(Constantes.SESSION_CURRENT_MEMBER, data.context);
    const currentTree = BasicPrimitivesService.sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE);
    let dialogRef: any = null;
    switch (data.name) {
      case 'properties':
        dialogRef = BasicPrimitivesService.dialogService.open(MemberInfosComponent, {
          context: {
            currentMember: data.context,
            currentTree: BasicPrimitivesService.currentTree
          },
          hasScroll: true
        });
        dialogRef.componentRef.instance.afterAction.subscribe(() => {
          BasicPrimitivesService.personService.findFromTree(currentTree.id).subscribe((res: HttpResponse<IPerson[]>) => {
            BasicPrimitivesService.updateMembers(res.body);
            BasicPrimitivesService.refreshDiagram();
          });
        });
        break;
      case 'add':
        dialogRef = BasicPrimitivesService.dialogService.open(MemberAddComponent, {
          context: {
            currentMember: data.context,
            currentTree: BasicPrimitivesService.currentTree
          },
          hasScroll: true
        });
        dialogRef.componentRef.instance.afterMemberSaved.subscribe(() => {
          BasicPrimitivesService.personService.findFromTree(currentTree.id).subscribe((res: HttpResponse<IPerson[]>) => {
            BasicPrimitivesService.updateMembers(res.body);
            BasicPrimitivesService.refreshDiagram();
          });
        });
        break;
      case 'delete':
        dialogRef = BasicPrimitivesService.dialogService.open(MemberRemoveComponent, {
          context: {
            currentMember: data.context,
            currentTree: BasicPrimitivesService.currentTree
          },
          hasScroll: true
        });
        dialogRef.componentRef.instance.afterAction.subscribe(() => {
          BasicPrimitivesService.personService.findFromTree(currentTree.id).subscribe((res: HttpResponse<IPerson[]>) => {
            BasicPrimitivesService.updateMembers(res.body);
            BasicPrimitivesService.refreshDiagram();
          });
        });
        break;
      default:
        break;
    }
  }

  /*
  onMouseClick(e?: any, data?: any): any {
  }
  onMouseDblClick(e?: any, data?: any): any {
  }
  */

  // La fonction qui permet de savoir ce que chaque item renvoie comme rendu
  onItemRender(e?: any, data?: any): any {
    const itemConfig = data.context;
    const element = data.element;
    // console.log('ITEM RENDER');
    // console.log(itemConfig, element);
    if (data.templateName === 'myFamilyTemplate') {
      const photo = data.element.childNodes[0].firstChild;
      if (itemConfig.avatarImg != null) {
        photo.src = SERVER_API_URL + itemConfig.avatarImg;
      } else if (itemConfig.imageContentType != null) {
        photo.src = 'data:' + itemConfig.imageContentType + ';base64,' + itemConfig.image;
      } else if (itemConfig.gender === 'MASCULIN') {
        photo.src = Constantes.MEMBER_DEFAULT_IMAGE_MALE;
      } else {
        photo.src = Constantes.MEMBER_DEFAULT_IMAGE_FEMALE;
      }
      photo.alt = itemConfig.title;

      const titleBackground = element.childNodes[1];
      if (itemConfig.gender === 'MASCULIN') {
        titleBackground.style.backgroundColor = itemConfig.itemTitleColor || common.Colors.RoyalBlue;
      } else {
        titleBackground.style.backgroundColor = itemConfig.itemTitleColor || common.Colors.Fuchsia;
      }

      const title = element.childNodes[1].firstChild;
      title.textContent = itemConfig.title;
    }
  }
  /*
  onHighlightRender(e?: any, data?: any): any {
  }
  onCursorRender(e?: any, data?: any): any {
  }
  */

  // -------------------------------------------------------------------------------------------------
  // La fonction qui permet de savoir comment s'affiche les elements
  getFamilyTemplate(): famdiagram.TemplateConfig {
    const result = new famdiagram.TemplateConfig();
    result.name = 'myFamilyTemplate';
    result.itemSize = new common.Size(105, 130);
    result.minimizedItemFillColor = null;
    result.minimizedItemSize = new common.Size(12, 12);
    result.itemTemplate = [
      'div',
      {
        style: {
          width: result.itemSize.width + 'px',
          height: result.itemSize.height + 'px'
        },
        class: ['bp-item', 'bp-corner-all', 'bt-item-frame']
      },
      [
        'div',
        {
          class: ['bp-item', 'bp-photo-frame', 'img-circle'],
          style: {
            top: '2px',
            left: '2px',
            width: '100px',
            height: '100px',
            'border-radius': '50%'
          }
        },
        [
          'img',
          {
            name: 'photo',
            class: ['bp-item', 'bp-title'],
            style: {
              width: '100px',
              height: '100px'
            }
          }
        ]
      ],
      [
        'div',
        {
          name: 'titleBackground',
          class: ['bp-item', 'bp-corner-all', 'bt-title-frame'],
          style: {
            top: '107px',
            left: '2px',
            width: '100px',
            height: '20px'
          }
        },
        [
          'div',
          {
            name: 'title',
            class: ['bp-item', 'bp-title'],
            style: {
              // top: '106px',
              left: '3px',
              width: '100px',
              height: '18px'
            }
          }
        ]
      ]
    ];
    return result;
  }
}
