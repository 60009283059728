import { Moment } from 'moment';
import { IPerson } from 'app/shared/model/person.model';

export interface ITreePhoto {
  id?: number;
  title?: string;
  photoContentType?: string;
  photo?: any;
  dateAdded?: Moment;
  addedByLogin?: string;
  addedById?: number;
  people?: IPerson[];
  treeId?: number;
  personId?: number;
}

export class TreePhoto implements ITreePhoto {
  constructor(
    public id?: number,
    public title?: string,
    public photoContentType?: string,
    public photo?: any,
    public dateAdded?: Moment,
    public addedByLogin?: string,
    public addedById?: number,
    public people?: IPerson[],
    public treeId?: number,
    public personId?: number
  ) {}
}
