import { Component, OnInit } from '@angular/core';
import { PersonTestimonyService } from 'app/entities/person-testimony/person-testimony.service';
import { PersonTestimony, IPersonTestimony } from 'app/shared/model/person-testimony.model';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';

@Component({
  selector: 'jhi-testimonies',
  templateUrl: './testimonies.component.html',
  styleUrls: ['./testimonies.component.scss']
})
export class TestimoniesComponent implements OnInit {
  currentMember: any;

  currentUser: any;

  currentTreeSecret: any;

  testimonies: Array<IPersonTestimony> = [];

  permission: any;

  isCollapsed = true;

  newTestimony = new PersonTestimony();

  updTestimony = new PersonTestimony();

  delTestimony = new PersonTestimony();

  constructor(
    private _testimonyService: PersonTestimonyService,
    private _sessionSt: SessionStorageService,
    private _localSt: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.currentMember = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_MEMBER);
    this.currentTreeSecret = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE_SECRET);
    this.permission = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE_PERMISSION);
    this.getTestimonies();
    console.log(this.currentMember);

    this.currentUser = this._sessionSt.retrieve(Constantes.CURRENT_USER);
    if (!this.currentUser || this.currentUser === null) {
      this.currentUser = this._localSt.retrieve(Constantes.CURRENT_USER);
    }
  }

  getTestimonies(): any {
    let criteria;
    if (this.currentTreeSecret && this.currentTreeSecret !== '') {
      criteria = {
        'onPersonId.equals': this.currentMember.id,
        'treeSecret.equals': this.currentTreeSecret
      };
    } else {
      criteria = {
        'onPersonId.equals': this.currentMember.id
      };
    }
    this._testimonyService.query(criteria).subscribe(
      (data: any) => {
        console.log(data);
        this.testimonies = data.body;
      },
      (error: any) => {
        console.log('ERROR!');
        console.log(error);
      }
    );
  }

  addTestimony(): void {
    this.newTestimony.onPersonId = this.currentMember.id;
    console.log(this.newTestimony);
    console.log(this.currentMember);
    this._testimonyService.create(this.newTestimony).subscribe(
      (data: any) => {
        console.log(data);
        this.getTestimonies();
      },
      (error: any) => {
        console.log('ERROR!');
        console.log(error);
      }
    );
  }

  startUpdate(testimony: any): void {
    this.updTestimony = testimony;
  }

  startDelete(testimony: any): void {
    this.delTestimony = testimony;
  }

  updateTestimony(): void {
    // this.testimoniesEvent.next({action: 'updateTestimony', object: this.updTestimony});
    if (this.updTestimony && this.updTestimony !== null) {
      this._testimonyService.update(this.updTestimony).subscribe(() => {
        this.startUpdate(null);
        this.getTestimonies();
      });
    }
  }

  deleteTestimony(): void {
    if (this.delTestimony.id && this.delTestimony !== null)
      this._testimonyService.delete(this.delTestimony.id).subscribe(
        () => {
          this.startDelete(null);
          this.getTestimonies();
        },
        () => {}
      );
    // this.testimoniesEvent.next({action: 'deleteTestimony', object: this.delTestimony});
  }
}
