import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { IPersonTestimony } from 'app/shared/model/person-testimony.model';

type EntityResponseType = HttpResponse<IPersonTestimony>;
type EntityArrayResponseType = HttpResponse<IPersonTestimony[]>;

@Injectable({ providedIn: 'root' })
export class PersonTestimonyService {
  public resourceUrl = SERVER_API_URL + 'api/person-testimonies';

  constructor(protected http: HttpClient) {}

  create(personTestimony: IPersonTestimony): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(personTestimony);
    return this.http
      .post<IPersonTestimony>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(personTestimony: IPersonTestimony): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(personTestimony);
    return this.http
      .put<IPersonTestimony>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IPersonTestimony>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IPersonTestimony[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(personTestimony: IPersonTestimony): IPersonTestimony {
    const copy: IPersonTestimony = Object.assign({}, personTestimony, {
      dateAdded: personTestimony.dateAdded && personTestimony.dateAdded.isValid() ? personTestimony.dateAdded.toJSON() : undefined,
      dateModified:
        personTestimony.dateModified && personTestimony.dateModified.isValid() ? personTestimony.dateModified.toJSON() : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateAdded = res.body.dateAdded ? moment(res.body.dateAdded) : undefined;
      res.body.dateModified = res.body.dateModified ? moment(res.body.dateModified) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((personTestimony: IPersonTestimony) => {
        personTestimony.dateAdded = personTestimony.dateAdded ? moment(personTestimony.dateAdded) : undefined;
        personTestimony.dateModified = personTestimony.dateModified ? moment(personTestimony.dateModified) : undefined;
      });
    }
    return res;
  }
}
