import { Moment } from 'moment';

export interface IPersonTestimony {
  id?: number;
  title?: string;
  content?: string;
  dateAdded?: Moment;
  dateModified?: Moment;
  addedByLogin?: string;
  addedById?: number;
  lastUpdateByLogin?: string;
  lastUpdateById?: number;
  onPersonId?: number;
}

export class PersonTestimony implements IPersonTestimony {
  constructor(
    public id?: number,
    public title?: string,
    public content?: string,
    public dateAdded?: Moment,
    public dateModified?: Moment,
    public addedByLogin?: string,
    public addedById?: number,
    public lastUpdateByLogin?: string,
    public lastUpdateById?: number,
    public onPersonId?: number
  ) {}
}
