import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { TreeService } from 'app/entities/tree/tree.service';
import { ITree } from 'app/shared/model/tree.model';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';

@Injectable({ providedIn: 'root' })
export class PersonalTreeNewResolve implements Resolve<ITree[]> {
  // On doit s'assurer que l'utilisateur n'essaye pas d'acceder aux arbres d'une autre personne
  constructor(
    private service: TreeService,
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ITree[]> | Observable<never> {
    const userLogin = route.params['ownerLogin'];
    let currentUser: any;
    currentUser = this.sessionStorage.retrieve(Constantes.CURRENT_USER);
    if (!currentUser) currentUser = this.localStorage.retrieve(Constantes.CURRENT_USER);
    // console.log(currentUser);
    if (userLogin && currentUser && currentUser.login === userLogin) {
      return of(currentUser);
    } else {
      this.router.navigate(['404']);
      return EMPTY;
    }
  }
}
