import { Moment } from 'moment';

export interface ISpouse {
  id?: number;
  weddingDate?: Moment;
  weddingPlace?: string;
  divorced?: boolean;
  dateDivorce?: Moment;
  dateAdded?: Moment;
  dateModified?: Moment;
  addedByLogin?: string;
  addedById?: number;
  wifeId?: number;
  husbandId?: number;
}

export class Spouse implements ISpouse {
  constructor(
    public id?: number,
    public weddingDate?: Moment,
    public weddingPlace?: string,
    public divorced?: boolean,
    public dateDivorce?: Moment,
    public dateAdded?: Moment,
    public dateModified?: Moment,
    public addedByLogin?: string,
    public addedById?: number,
    public wifeId?: number,
    public husbandId?: number
  ) {
    this.divorced = this.divorced || false;
  }
}
