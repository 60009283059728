export class Constantes {
  static readonly CURRENT_USER_TOKEN = 'authenticationToken';
  // An array that contains all the ids of the trees that are in the cookies
  static readonly CURRENT_USER = 'currentUser';
  // The tree that is opened in the current Session
  static readonly SESSION_CURRENT_TREE = 'currentTree';
  // The tree's secret that is opened in the current Session
  static readonly SESSION_CURRENT_TREE_SECRET = 'currentTreeSecret';
  // The Permission Object for the current Tree
  static readonly SESSION_CURRENT_TREE_PERMISSION = 'currentTreePermission';
  // The members of the tree that is opened in the current Session
  static readonly SESSION_CURRENT_TREE_MEMBERS = 'currentTreeMembers';
  // The member in which we're trying to make an action stored in the session
  static readonly SESSION_CURRENT_MEMBER = 'currentMember';

  static readonly MEMBER_DEFAULT_IMAGE_MALE = '/content/images/avatar_male_cheikh.jpg';

  static readonly MEMBER_DEFAULT_IMAGE_FEMALE = '/content/images/avatar_female_hijab.jpg';
}
