import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Resolve, Router, ActivatedRouteSnapshot, Routes } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { BpTreeComponent } from './bp-tree.component';
import { ITree, Tree } from 'app/shared/model/tree.model';
import { TreeService } from 'app/entities/tree/tree.service';

@Injectable({ providedIn: 'root' })
export class BpTreeResolve implements Resolve<ITree> {
  constructor(private service: TreeService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ITree> | Observable<never> {
    const ownerLogin = route.params['owner'];
    const codeTree = route.params['codeTree'];
    const secret = route.params['secret'];
    if (ownerLogin && codeTree && !secret) {
      // const req = {
      //   'code.equals': codeTree,
      //   'ownerLogin.equals': ownerLogin
      // };
      return this.service.findByOwnerAndCode(ownerLogin, codeTree).pipe(
        flatMap((tree: HttpResponse<Tree>) => {
          if (tree.body) {
            return of(tree.body);
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }
    if (ownerLogin && codeTree && secret) {
      return this.service.findByOwnerAndCodeAndSecret(ownerLogin, codeTree, secret).pipe(
        flatMap((tree: HttpResponse<Tree>) => {
          if (tree.body) {
            return of(tree.body);
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }
    this.router.navigate(['404']);
    return EMPTY;
  }
}

export const BP_TREE_ROUTE: Routes = [
  {
    path: ':owner/tree/:codeTree',
    component: BpTreeComponent,
    resolve: {
      tree: BpTreeResolve
    },
    data: {
      authorities: [],
      pageTitle: 'home.title'
    }
  },
  {
    path: ':owner/tree/:codeTree/secret/:secret',
    component: BpTreeComponent,
    resolve: {
      tree: BpTreeResolve
    },
    data: {
      authorities: [],
      pageTitle: 'home.title'
    }
  }
];
