import { NgModule } from '@angular/core';
import { JhipsterJabootSharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { PUBLIC_TREES_ROUTE } from './public-trees.route';
import { PublicTreesComponent } from './public-trees.component';
import { NbCardModule, NbButtonModule, NbListModule, NbUserModule, NbIconModule, NbInputModule } from '@nebular/theme';

@NgModule({
  imports: [
    JhipsterJabootSharedModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbListModule,
    NbIconModule,
    NbUserModule,
    RouterModule.forChild([PUBLIC_TREES_ROUTE])
  ],
  declarations: [PublicTreesComponent]
})
export class PublicTreesModule {}
