import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { IPerson } from 'app/shared/model/person.model';
import { SessionStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';
import { INSTANT_FORMAT } from 'app/shared/constants/input.constants';

type EntityResponseType = HttpResponse<IPerson>;
type EntityArrayResponseType = HttpResponse<IPerson[]>;

@Injectable({ providedIn: 'root' })
export class PersonService {
  public resourceUrl = SERVER_API_URL + 'api/people';

  constructor(protected http: HttpClient, private _sessionSt: SessionStorageService) {}

  create(person: IPerson): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(person);
    return this.http
      .post<IPerson>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(person: IPerson): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(person);
    return this.http
      .put<IPerson>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IPerson>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findFromTree(treeId: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<IPerson[]>(`${this.resourceUrl}/of_bp_tree/${treeId}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findFromTreeSecret(treeId: number, secret: string): Observable<EntityArrayResponseType> {
    return this.http
      .get<IPerson[]>(`${this.resourceUrl}/of_bp_tree/${treeId}/with_secret/${secret}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findPossibleRelatives(treeId: number, id: number, relationship: string): Observable<EntityArrayResponseType> {
    return this.http
      .get<IPerson[]>(`${this.resourceUrl}/${id}/of_tree/${treeId}/possible/${relationship}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IPerson[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(person: IPerson): IPerson {
    const copy: IPerson = Object.assign({}, person, {
      // dateBirth: person.dateBirth && person.dateBirth.isValid() ? person.dateBirth.toJSON() : undefined,
      // dateDeath: person.dateDeath && person.dateDeath.isValid() ? person.dateDeath.toJSON() : undefined,
      // dateAdded: person.dateAdded && person.dateAdded.isValid() ? person.dateAdded.toJSON() : undefined,
      // dateModified: person.dateModified && person.dateModified.isValid() ? person.dateModified.toJSON() : undefined
      dateBirth: person.dateBirth && person.dateBirth.isValid() ? person.dateBirth.format(INSTANT_FORMAT) : undefined,
      dateDeath: person.dateDeath && person.dateDeath.isValid() ? person.dateDeath.format(INSTANT_FORMAT) : undefined,
      dateAdded: person.dateAdded && person.dateAdded.isValid() ? person.dateAdded.format(INSTANT_FORMAT) : undefined,
      dateModified: person.dateModified && person.dateModified.isValid() ? person.dateModified.format(INSTANT_FORMAT) : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateBirth = res.body.dateBirth ? moment(res.body.dateBirth) : undefined;
      res.body.dateDeath = res.body.dateDeath ? moment(res.body.dateDeath) : undefined;
      res.body.dateAdded = res.body.dateAdded ? moment(res.body.dateAdded) : undefined;
      res.body.dateModified = res.body.dateModified ? moment(res.body.dateModified) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((person: IPerson) => {
        person.dateBirth = person.dateBirth ? moment(person.dateBirth) : undefined;
        person.dateDeath = person.dateDeath ? moment(person.dateDeath) : undefined;
        person.dateAdded = person.dateAdded ? moment(person.dateAdded) : undefined;
        person.dateModified = person.dateModified ? moment(person.dateModified) : undefined;
      });
    }
    return res;
  }

  hashPersonFromServer(members: Array<any>): any {
    const membersHashed = {};
    // Dans le cas ou il y a un currentMember dans le session storage, on le modifie lors du hashage
    let currentMember = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_MEMBER);
    if (currentMember != null) {
      members.map(element => {
        membersHashed[element.id] = { ...element };
        if (element.id === currentMember.id) {
          currentMember = { ...element };
        }
        delete membersHashed[element.id].image;
        delete membersHashed[element.id].imageContentType;
      });
      this._sessionSt.store(Constantes.SESSION_CURRENT_MEMBER, currentMember);
    } else {
      members.map(element => {
        membersHashed[element.id] = { ...element };
        delete membersHashed[element.id].image;
        delete membersHashed[element.id].imageContentType;
      });
    }
    return membersHashed;
  }
}
