import { Moment } from 'moment';
import { IParent } from 'app/shared/model/parent.model';
import { ISpouse } from 'app/shared/model/spouse.model';
import { IPersonTree } from 'app/shared/model/person-tree.model';
import { IPersonTestimony } from 'app/shared/model/person-testimony.model';
import { ITreePhoto } from 'app/shared/model/tree-photo.model';
import { Gender } from 'app/shared/model/enumerations/gender.model';

export interface IPerson {
  id?: number;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  dateBirth?: Moment;
  placeBirth?: string;
  dateDeath?: Moment;
  placeDeath?: string;
  dateAdded?: Moment;
  dateModified?: Moment;
  avatarId?: number;
  children?: IParent[];
  parents?: IParent[];
  spouseHusbands?: ISpouse[];
  spouseWives?: ISpouse[];
  personTrees?: IPersonTree[];
  testimonies?: IPersonTestimony[];
  addedByLogin?: string;
  addedById?: number;
  lastUpdateByLogin?: string;
  lastUpdateById?: number;
  treePhotos?: ITreePhoto[];

  // External properties
  defaultImageContentType?: string;
  defaultImage?: any;
  image?: any;
  imageContentType?: any;

  parentOf?: number;
  childOf?: number;
  husbandOf?: number;
  wifeOf?: number;

  treeId?: number;
}

export class Person implements IPerson {
  constructor(
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public gender?: Gender,
    public dateBirth?: Moment,
    public placeBirth?: string,
    public dateDeath?: Moment,
    public placeDeath?: string,
    public dateAdded?: Moment,
    public dateModified?: Moment,
    public avatarId?: number,
    public children?: IParent[],
    public parents?: IParent[],
    public spouseHusbands?: ISpouse[],
    public spouseWives?: ISpouse[],
    public personTrees?: IPersonTree[],
    public testimonies?: IPersonTestimony[],
    public addedByLogin?: string,
    public addedById?: number,
    public lastUpdateByLogin?: string,
    public lastUpdateById?: number,
    public treePhotos?: ITreePhoto[]
  ) {}
}

export class PersonWithAvatar implements IPerson {
  constructor(
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public gender?: Gender,
    public dateBirth?: Moment,
    public placeBirth?: string,
    public dateDeath?: Moment,
    public placeDeath?: string,
    public dateAdded?: Moment,
    public dateModified?: Moment,
    public avatarId?: number,
    public defaultImageContentType?: string,
    public defaultImage?: any,
    public parentOf?: number,
    public childOf?: number,
    public husbandOf?: number,
    public treeId?: number,
    public children?: IParent[],
    public parents?: IParent[],
    public spouseHusbands?: ISpouse[],
    public spouseWives?: ISpouse[],
    public personTrees?: IPersonTree[],
    public testimonies?: IPersonTestimony[],
    public addedByLogin?: string,
    public addedById?: number,
    public lastUpdateByLogin?: string,
    public lastUpdateById?: number,
    public treePhotos?: ITreePhoto[]
  ) {}
}
