import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { ITree } from 'app/shared/model/tree.model';

type EntityResponseType = HttpResponse<ITree>;
type EntityArrayResponseType = HttpResponse<ITree[]>;

@Injectable({ providedIn: 'root' })
export class TreeService {
  public resourceUrl = SERVER_API_URL + 'api/trees';

  constructor(protected http: HttpClient) {}

  create(tree: ITree): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(tree);
    return this.http
      .post<ITree>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(tree: ITree): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(tree);
    return this.http
      .put<ITree>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  generateNewSecret(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ITree>(`${this.resourceUrl}/${id}/generate_secret`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ITree>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findByOwnerAndCode(ownerLogin: string, code: string): Observable<EntityResponseType> {
    return this.http
      .get<ITree>(`${this.resourceUrl}/owner/${ownerLogin}/code/${code}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findByOwnerAndCodeAndSecret(ownerLogin: string, code: string, secret: string): Observable<EntityResponseType> {
    return this.http
      .get<ITree>(`${this.resourceUrl}/owner/${ownerLogin}/code/${code}/secret/${secret}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findWithSecret(id: number): Observable<EntityResponseType> {
    return this.http
      .get<ITree>(`${this.resourceUrl}/${id}/with_secret`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findAllowedTrees(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ITree[]>(`${this.resourceUrl}/allowed_for_me`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findPublic(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ITree[]>(`${this.resourceUrl}/public`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ITree[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  protected convertDateFromClient(tree: ITree): ITree {
    const copy: ITree = Object.assign({}, tree, {
      dateAdded: tree.dateAdded && tree.dateAdded.isValid() ? tree.dateAdded.toJSON() : undefined,
      dateModified: tree.dateModified && tree.dateModified.isValid() ? tree.dateModified.toJSON() : undefined
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateAdded = res.body.dateAdded ? moment(res.body.dateAdded) : undefined;
      res.body.dateModified = res.body.dateModified ? moment(res.body.dateModified) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((tree: ITree) => {
        tree.dateAdded = tree.dateAdded ? moment(tree.dateAdded) : undefined;
        tree.dateModified = tree.dateModified ? moment(tree.dateModified) : undefined;
      });
    }
    return res;
  }
}
