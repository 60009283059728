import { NgModule } from '@angular/core';
import { MemberInfosComponent } from './member-infos.component';
import { JhipsterJabootSharedModule } from 'app/shared/shared.module';
import { NbDialogModule, NbCardModule, NbButtonModule, NbInputModule, NbSelectModule, NbSpinnerModule } from '@nebular/theme';
import { PersonalComponent } from './personal/personal.component';
import { RelativesComponent } from './relatives/relatives.component';
import { PhotosComponent } from './photos/photos.component';
import { TestimoniesComponent } from './testimonies/testimonies.component';
import { NbMomentDateModule } from '@nebular/moment';

@NgModule({
  declarations: [MemberInfosComponent, PersonalComponent, RelativesComponent, PhotosComponent, TestimoniesComponent],
  imports: [
    JhipsterJabootSharedModule,
    NbDialogModule.forChild(),
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    NbSelectModule,
    NbMomentDateModule,
    NbSpinnerModule
  ],
  entryComponents: [MemberInfosComponent]
})
export class MemberInfosModule {}
