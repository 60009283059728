import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { PersonService } from 'app/entities/person/person.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { SessionStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';

@Component({
  selector: 'jhi-member-remove',
  templateUrl: './member-remove.component.html',
  styleUrls: ['./member-remove.component.scss']
})
export class MemberRemoveComponent implements OnInit {
  @Output()
  afterAction = new EventEmitter<{
    action: string;
    object: any;
  }>();

  currentTree: any = null;

  currentMember: any = null;

  isDeleting = false;

  constructor(protected dialogRef: NbDialogRef<any>, protected _sessionSt: SessionStorageService, protected personService: PersonService) {}

  ngOnInit(): void {
    this.currentMember = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_MEMBER);
    // console.log(this.currentMember);
  }

  deleteMember(): void {
    this.isDeleting = true;
    this.subscribeToDeleteResponse(this.personService.delete(this.currentMember.id));
  }

  protected subscribeToDeleteResponse(result: Observable<HttpResponse<{}>>): void {
    result.subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );
  }

  protected onDeleteSuccess(): void {
    this.isDeleting = false;
    this.afterAction.next();
    this.close();
    // this.previousState();
  }

  protected onDeleteError(): void {
    this.isDeleting = false;
  }

  close(): void {
    this.dialogRef.close();
  }
}
