import { Component, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JhiDataUtils, JhiEventManager, JhiEventWithContent, JhiFileLoadError } from 'ng-jhipster';
import { PersonService } from 'app/entities/person/person.service';
import { NbDateService } from '@nebular/theme';
import { DATE_TIME_FORMAT } from 'app/shared/constants/input.constants';
import { IPerson, PersonWithAvatar } from 'app/shared/model/person.model';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { HttpResponse } from '@angular/common/http';
import { AlertError } from 'app/shared/alert/alert-error.model';
import { SessionStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';

@Component({
  selector: 'jhi-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {
  @Output()
  afterMemberUpdated = new EventEmitter<{
    action: string;
    object: any;
  }>();

  status = false;
  currentMember: any = {};

  currentTree: any = {};

  permission: any = {};

  isUpdating = false;

  isSaving = false;

  // La valeur maximale qu'il est possible d'affecter a une date
  dateMax: Date = new Date();

  editForm = this.fb.group({
    id: [],
    firstName: [
      null,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
        Validators.pattern(
          "^[A-Z]([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+(([ ][A-Z]{1})?([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+)*$"
        )
      ]
    ],
    lastName: [
      null,
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(25),
        Validators.pattern(
          "^[A-Z]([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+(([ ][A-Z]{1})?([',.-][A-Z]?)?[a-zàáâäçèéêëìíîïñòóôðöùúûü]+)*$"
        )
      ]
    ],
    gender: [null],
    dateBirth: [],
    placeBirth: [],
    dateDeath: [],
    placeDeath: [],
    defaultImage: [],
    defaultImageContentType: []
  });

  constructor(
    protected elementRef: ElementRef,
    protected eventManager: JhiEventManager,
    protected _sessionSt: SessionStorageService,
    private fb: FormBuilder,
    protected dataUtils: JhiDataUtils,
    protected personService: PersonService,
    protected dateService: NbDateService<Date>
  ) {
    this.dateMax = this.dateService.today();
  }

  ngOnInit(): void {
    this.currentMember = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_MEMBER);
    this.currentTree = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE);
    this.permission = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE_PERMISSION);
    console.log(this.permission);
    this.updateForm(this.currentMember);
    this._sessionSt.observe(Constantes.SESSION_CURRENT_TREE).subscribe(value => (this.currentTree = value));
    this._sessionSt.observe(Constantes.SESSION_CURRENT_MEMBER).subscribe(value => this.setCurrentMember(value));
    this._sessionSt.observe(Constantes.SESSION_CURRENT_TREE_PERMISSION).subscribe(value => (this.permission = value));
  }

  setCurrentMember(currentMember: any): void {
    this.currentMember = currentMember;
    this.updateForm(this.currentMember);
  }

  updateForm(person: IPerson): void {
    console.log(person.dateBirth);
    if (person) {
      this.editForm.patchValue({
        id: person.id,
        firstName: person.firstName,
        lastName: person.lastName,
        gender: person.gender,

        // dateBirth: person.dateBirth ? person.dateBirth.format(DATE_TIME_FORMAT) : null,
        dateBirth: person.dateBirth,
        placeBirth: person.placeBirth,
        // dateDeath: person.dateDeath ? person.dateDeath.format(DATE_TIME_FORMAT) : null,
        dateDeath: person.dateDeath,
        placeDeath: person.placeDeath,
        defaultImage: person.image,
        defaultImageContentType: person.imageContentType
      });
    } else {
      person = {};
      this.editForm.patchValue({
        id: person.id,
        firstName: person.firstName,
        lastName: person.lastName,
        gender: person.gender,
        // dateBirth: person.dateBirth ? person.dateBirth.format(DATE_TIME_FORMAT) : null,
        dateBirth: person.dateBirth,
        placeBirth: person.placeBirth,
        // dateDeath: person.dateDeath ? person.dateDeath.format(DATE_TIME_FORMAT) : null,
        dateDeath: person.dateDeath,
        placeDeath: person.placeDeath,
        defaultImage: person.image,
        defaultImageContentType: person.imageContentType
      });
    }
  }

  byteSize(base64String: string): string {
    return this.dataUtils.byteSize(base64String);
  }

  openFile(contentType: string, base64String: string): void {
    this.dataUtils.openFile(contentType, base64String);
  }

  setFileData(event: Event, field: string, isImage: boolean): void {
    this.dataUtils.loadFileToForm(event, this.editForm, field, isImage).subscribe(null, (err: JhiFileLoadError) => {
      this.eventManager.broadcast(
        new JhiEventWithContent<AlertError>('jhipsterJabootApp.error', { ...err, key: 'error.file.' + err.key })
      );
    });
  }

  clearInputImage(field: string, fieldContentType: string, idInput: string): void {
    this.editForm.patchValue({
      [field]: null,
      [fieldContentType]: null
    });
    if (this.elementRef && idInput && this.elementRef.nativeElement.querySelector('#' + idInput)) {
      this.elementRef.nativeElement.querySelector('#' + idInput).value = null;
    }
  }

  previousState(): void {
    window.history.back();
  }

  save(): void {
    this.isSaving = true;
    const person = this.createFromForm();
    if (person.id !== undefined) {
      this.subscribeToSaveResponse(this.personService.update(person));
    } else {
      this.subscribeToSaveResponse(this.personService.create(person));
    }
  }

  private createFromForm(): IPerson {
    return {
      ...new PersonWithAvatar(),
      // id: this.editForm.get(['id'])!.value,
      id: this.currentMember['id'],
      firstName: this.editForm.get(['firstName'])!.value,
      lastName: this.editForm.get(['lastName'])!.value,
      gender: this.editForm.get(['gender'])!.value,
      dateBirth: this.editForm.get(['dateBirth'])!.value ? moment(this.editForm.get(['dateBirth'])!.value, DATE_TIME_FORMAT) : undefined,
      placeBirth: this.editForm.get(['placeBirth'])!.value,
      dateDeath: this.editForm.get(['dateDeath'])!.value ? moment(this.editForm.get(['dateDeath'])!.value, DATE_TIME_FORMAT) : undefined,
      placeDeath: this.editForm.get(['placeDeath'])!.value,
      defaultImageContentType: this.editForm.get(['defaultImageContentType'])!.value,
      defaultImage: this.editForm.get(['defaultImage'])!.value
    };
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IPerson>>): void {
    result.subscribe(
      res => this.onSaveSuccess(res.body),
      () => this.onSaveError()
    );
  }

  protected onSaveSuccess(member: any): void {
    this.isSaving = false;
    this.isUpdating = false;
    this.afterMemberUpdated.emit({ action: 'updateMember', object: member });

    // this.previousState();
  }

  protected onSaveError(): void {
    this.isSaving = false;
  }

  momentFormat(date: string, format: string): string {
    return moment(date).format(format);
  }
}
