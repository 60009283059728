import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SpouseService } from 'app/entities/spouse/spouse.service';
import { ParentService } from 'app/entities/parent/parent.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';
import { isArray } from 'util';

@Component({
  selector: 'jhi-relatives',
  templateUrl: './relatives.component.html',
  styleUrls: ['./relatives.component.scss']
})
export class RelativesComponent implements OnInit {
  @Output()
  afterRelationRemoved = new EventEmitter<any>();
  // afterRelationRemoved = new EventEmitter<{
  //   action: string;
  //   object: any;
  // }>();

  currentMember: any;

  currentTree: any;

  currentTreeMembers: any;

  permission: any;

  isDeleting = false;

  father: any = null;

  mother: any = null;

  sons: any[] | null = null;

  daughters: any[] | null = null;

  husbands: any[] | null = null;

  wives: any[] | null = null;

  confirmRemove: any[] = [];

  constructor(
    private _spouseService: SpouseService,
    private _parentService: ParentService,
    private _localSt: LocalStorageService,
    private _sessionSt: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.currentMember = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_MEMBER);
    this.currentTree = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE);
    this.currentTreeMembers = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE_MEMBERS);
    this.permission = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE_PERMISSION);
    this.setRelatives(this.currentTreeMembers);
    this._sessionSt.observe(Constantes.SESSION_CURRENT_TREE).subscribe(value => (this.currentTree = value));
    this._sessionSt.observe(Constantes.SESSION_CURRENT_MEMBER).subscribe(value => (this.currentMember = value));
    this._sessionSt.observe(Constantes.SESSION_CURRENT_TREE_MEMBERS).subscribe(value => this.setRelatives(value));
    this._sessionSt.observe(Constantes.SESSION_CURRENT_TREE_PERMISSION).subscribe(value => (this.permission = value));
  }

  setRelatives(currentTreeMembers: any): void {
    this.currentTreeMembers = currentTreeMembers;
    // console.log(this.currentMember);
    const fatherId =
      isArray(this.currentMember.fathers) && this.currentMember.fathers.length > 0 ? this.currentMember.fathers[0] : undefined;
    const motherId =
      isArray(this.currentMember.mothers) && this.currentMember.mothers.length > 0 ? this.currentMember.mothers[0] : undefined;
    const husbandsId =
      isArray(this.currentMember.husbands) && this.currentMember.husbands.length > 0 ? this.currentMember.husbands : undefined;
    const wivesId = isArray(this.currentMember.wives) && this.currentMember.wives.length > 0 ? this.currentMember.wives : undefined;
    const sonsId = isArray(this.currentMember.sons) && this.currentMember.sons.length > 0 ? this.currentMember.sons : undefined;
    const daughtersId =
      isArray(this.currentMember.daughters) && this.currentMember.daughters.length > 0 ? this.currentMember.daughters : undefined;
    if (fatherId) {
      // console.log('FATHERS');
      // console.log(fatherId);
      this.father = this.setMemberImage(this.currentTreeMembers[fatherId]);
      this.confirmRemove[this.father['id']] = false;
    }
    if (motherId) {
      // console.log('MOTHER');
      // console.log(motherId);
      this.mother = this.setMemberImage(this.currentTreeMembers[motherId]);
      this.confirmRemove[this.mother['id']] = false;
    }
    if (husbandsId) {
      // console.log('HUSBANDS');
      // console.log(husbandsId);
      this.husbands = [];
      husbandsId.forEach((element: any) => {
        const husband = this.setMemberImage(this.currentTreeMembers[element]);
        this.husbands != null ? this.husbands.push(husband) : undefined;
      });
    }
    if (wivesId) {
      // console.log('WIVES');
      // console.log(wivesId);
      this.wives = [];
      wivesId.forEach((element: any) => {
        const wife = this.setMemberImage(this.currentTreeMembers[element]);
        this.wives != null ? this.wives.push(wife) : undefined;
      });
    }
    if (sonsId) {
      // console.log('SONS');
      // console.log(sonsId);
      this.sons = [];
      sonsId.forEach((element: any) => {
        const son = this.setMemberImage(this.currentTreeMembers[element]);
        this.sons != null ? this.sons.push(son) : undefined;
      });
    }
    if (daughtersId) {
      // console.log('DAUGHTERS');
      // console.log(daughtersId);
      this.daughters = [];
      daughtersId.forEach((element: any) => {
        const daughter = this.setMemberImage(this.currentTreeMembers[element]);
        this.daughters != null ? this.daughters.push(daughter) : undefined;
      });
    }
  }

  setMemberImage(member: any): any {
    if (member.imageContentType != null) {
      member.defaultImage = 'data:' + member.imageContentType + ';base64,' + member.image;
    } else if (member.gender === 'MASCULIN') {
      member.defaultImage = Constantes.MEMBER_DEFAULT_IMAGE_MALE;
    } else {
      member.defaultImage = Constantes.MEMBER_DEFAULT_IMAGE_FEMALE;
    }
    return member;
  }

  switchButtonRemove(index: string, newState = true): void {
    this.confirmRemove[index] = newState;
  }

  removeRelation(relation: string, relative: any): void {
    let relationObject: any;
    if (relation === 'father' || relation === 'mother') {
      relationObject = {
        parentId: relative['id'],
        childId: this.currentMember['id']
      };
    } else if (relation === 'husband') {
      relationObject = {
        husbandId: relative['id'],
        wifeId: this.currentMember['id']
      };
    } else if (relation === 'wife') {
      relationObject = {
        husbandId: this.currentMember['id'],
        wifeId: relative['id']
      };
    } else if (relation === 'son' || relation === 'daughter') {
      relationObject = {
        parentId: this.currentMember['id'],
        childId: relative['id']
      };
    }

    if (relation === 'father' || relation === 'mother' || relation === 'daughter' || relation === 'son') {
      this.subscribeToDeleteResponse(this._parentService.deleteRelation(relationObject));
    }
    if (relation === 'husband' || relation === 'wife') {
      this.subscribeToDeleteResponse(this._spouseService.deleteRelation(relationObject));
    }

    this.switchButtonRemove(relative['id'], false);
    // console.log({action: actionn, object: relationObject});
    // this.updateRelationEvent.next({action: actionn, object: relationObject});
  }

  protected subscribeToDeleteResponse(result: Observable<HttpResponse<{}>>): void {
    result.subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );
  }

  protected onDeleteSuccess(): void {
    this.isDeleting = false;
    this.afterRelationRemoved.next(true);
    // this.previousState();
  }

  protected onDeleteError(): void {
    this.isDeleting = false;
  }
}
