import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'person',
        loadChildren: () => import('./person/person.module').then(m => m.JhipsterJabootPersonModule)
      },
      {
        path: 'spouse',
        loadChildren: () => import('./spouse/spouse.module').then(m => m.JhipsterJabootSpouseModule)
      },
      {
        path: 'parent',
        loadChildren: () => import('./parent/parent.module').then(m => m.JhipsterJabootParentModule)
      },
      {
        path: 'person-testimony',
        loadChildren: () => import('./person-testimony/person-testimony.module').then(m => m.JhipsterJabootPersonTestimonyModule)
      },
      {
        path: 'person-tree',
        loadChildren: () => import('./person-tree/person-tree.module').then(m => m.JhipsterJabootPersonTreeModule)
      },
      {
        path: 'tree',
        loadChildren: () => import('./tree/tree.module').then(m => m.JhipsterJabootTreeModule)
      },
      {
        path: 'tree-photo',
        loadChildren: () => import('./tree-photo/tree-photo.module').then(m => m.JhipsterJabootTreePhotoModule)
      },
      {
        path: 'tree-view',
        loadChildren: () => import('./tree-view/tree-view.module').then(m => m.JhipsterJabootTreeViewModule)
      },
      {
        path: 'tree-popularity',
        loadChildren: () => import('./tree-popularity/tree-popularity.module').then(m => m.JhipsterJabootTreePopularityModule)
      },
      {
        path: 'tree-permission',
        loadChildren: () => import('./tree-permission/tree-permission.module').then(m => m.JhipsterJabootTreePermissionModule)
      },
      {
        path: 'person-avatar',
        loadChildren: () => import('./person-avatar/person-avatar.module').then(m => m.JhipsterJabootPersonAvatarModule)
      }
      /* jhipster-needle-add-entity-route - JHipster will add entity modules routes here */
    ])
  ]
})
export class JhipsterJabootEntityModule {}
