import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TreePhotoService } from 'app/entities/tree-photo/tree-photo.service';
import { AlertError } from 'app/shared/alert/alert-error.model';
import { Constantes } from 'app/shared/constants.const';
import { ITreePhoto, TreePhoto } from 'app/shared/model/tree-photo.model';
import { JhiDataUtils, JhiEventManager, JhiEventWithContent, JhiFileLoadError } from 'ng-jhipster';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'jhi-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {
  currentUser: any;

  currentTree: any = null;

  currentTreeSecret: any = null;

  currentMember: any = null;

  photos: Array<ITreePhoto> = [];

  permission: any;

  isCollapsed = true;

  newPhoto = new TreePhoto();

  newMemberForm = this.fb.group({
    id: [],
    title: [null, [Validators.required]],
    photo: [null, [Validators.required]],
    photoContentType: [null, [Validators.required]]
    // dateAdded: [],
    // dateModified: [],
    // addedById: [null, Validators.required],
    // lastUpdateById: []
  });

  constructor(
    private _treePhotoService: TreePhotoService,
    private fb: FormBuilder,
    protected elementRef: ElementRef,
    protected dataUtils: JhiDataUtils,
    protected eventManager: JhiEventManager,
    private _sessionSt: SessionStorageService,
    private _localSt: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.currentMember = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_MEMBER);
    this.currentTree = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE);
    this.currentTreeSecret = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE_SECRET);
    this.permission = this._sessionSt.retrieve(Constantes.SESSION_CURRENT_TREE_PERMISSION);
    this.getPhotos();
    this.currentUser = this._sessionSt.retrieve(Constantes.CURRENT_USER);
    if (!this.currentUser || this.currentUser === null) {
      this.currentUser = this._localSt.retrieve(Constantes.CURRENT_USER);
    }
  }

  getPhotos(): any {
    let criteria;
    if (this.currentTreeSecret && this.currentTreeSecret !== '') {
      criteria = {
        'personId.equals': this.currentMember.id,
        'treeSecret.equals': this.currentTreeSecret
      };
    } else {
      criteria = {
        'personId.equals': this.currentMember.id
      };
    }
    this._treePhotoService.query(criteria).subscribe(
      (data: any) => {
        console.log(data);
        this.photos = data.body;
      },
      (error: any) => {
        console.log('ERROR!');
        console.log(error);
      }
    );
  }

  save(): void {
    // this.isSaving = true;
    // console.log(this.newMemberForm.get(['firstName'])!.value);
    const person = this.createFromForm();
    console.log(person);
    if (person.id !== undefined && person.id != null) {
      this._treePhotoService.update(person).subscribe(
        () => {
          this.getPhotos();
        },
        () => {}
      );
    } else {
      this._treePhotoService.create(person).subscribe(
        () => {
          this.getPhotos();
        },
        () => {}
      );
    }
  }

  delete(photoId: number): void {
    this._treePhotoService.delete(photoId).subscribe(() => {
      this.getPhotos();
    });
  }

  private createFromForm(): ITreePhoto {
    return {
      ...new TreePhoto(),
      id: this.newMemberForm.get(['id'])!.value,
      title: this.newMemberForm.get(['title'])!.value,
      photo: this.newMemberForm.get(['photo'])!.value,
      photoContentType: this.newMemberForm.get(['photoContentType'])!.value,
      treeId: this.currentTree.id,
      personId: this.currentMember.id
    };
  }

  // Functions used to handle the Image(defaultImage)
  byteSize(base64String: string): string {
    return this.dataUtils.byteSize(base64String);
  }

  setFileData(event: Event, field: string, isImage: boolean): void {
    this.dataUtils.loadFileToForm(event, this.newMemberForm, field, isImage).subscribe(null, (err: JhiFileLoadError) => {
      this.eventManager.broadcast(
        new JhiEventWithContent<AlertError>('jhipsterJabootApp.error', { ...err, key: 'error.file.' + err.key })
      );
    });
  }

  clearInputImage(field: string, fieldContentType: string, idInput: string): void {
    this.newMemberForm.patchValue({
      [field]: null,
      [fieldContentType]: null
    });
    if (this.elementRef && idInput && this.elementRef.nativeElement.querySelector('#' + idInput)) {
      this.elementRef.nativeElement.querySelector('#' + idInput).value = null;
    }
  }
  // ------------------------------------------------------
}
