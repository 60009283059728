import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TreeService } from 'app/entities/tree/tree.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ITree, Tree } from 'app/shared/model/tree.model';

import { slugify } from 'app/shared/util/my-functions.util';
import { Router } from '@angular/router';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { Constantes } from 'app/shared/constants.const';

@Component({
  selector: 'jhi-personal-tree-new',
  templateUrl: './personal-tree-new.component.html',
  styleUrls: ['./personal-tree-new.component.scss']
})
export class PersonalTreeNewComponent implements OnInit {
  isSaving = false;

  currentUser: any;

  newTreeForm = this.fb.group({
    name: [
      null,
      [Validators.required, Validators.minLength(2), Validators.maxLength(25), Validators.pattern('^[A-Z][a-z]+([ ][A-Z][a-z]+)*$')]
    ],
    code: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(25)]],
    description: [],
    visibility: ['PUBLIC']
  });

  constructor(
    protected treeService: TreeService,
    private router: Router,
    private fb: FormBuilder,
    protected _sessionSt: SessionStorageService,
    protected _localSt: LocalStorageService
  ) {}

  ngOnInit(): void {
    const nameElement = this.newTreeForm.get(['name']);
    if (nameElement)
      nameElement.valueChanges.subscribe(data => {
        const codeElement = this.newTreeForm.get(['code']);
        if (codeElement) codeElement.setValue(slugify(data));
      });
  }

  previousState(): void {
    window.history.back();
  }

  save(): void {
    this.isSaving = true;
    const tree = this.createFromForm();
    console.log(tree);
    this.subscribeToSaveResponse(this.treeService.create(tree));
  }

  private createFromForm(): ITree {
    return {
      ...new Tree(),
      name: this.newTreeForm.get(['name'])!.value,
      code: this.newTreeForm.get(['code'])!.value,
      description: this.newTreeForm.get(['description'])!.value,
      visibility: this.newTreeForm.get(['visibility'])!.value
    };
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<ITree>>): void {
    result.subscribe(
      res => this.onSaveSuccess(res.body),
      () => this.onSaveError()
    );
  }

  protected onSaveSuccess(data: any): void {
    // this.previousState();
    this.currentUser = this._sessionSt.retrieve(Constantes.CURRENT_USER);
    if (!this.currentUser) {
      this.currentUser = this._localSt.retrieve(Constantes.CURRENT_USER);
    }
    if (this.currentUser) this.router.navigate(['/' + this.currentUser.login + '/tree/' + data.code + '/details']);
    // this.isSaving = false;
  }

  protected onSaveError(): void {
    this.isSaving = false;
  }
}
