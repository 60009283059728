import { NgModule } from '@angular/core';
import { JhipsterJabootSharedModule } from 'app/shared/shared.module';
import {
  NbButtonModule,
  NbListModule,
  NbIconModule,
  NbCardModule,
  NbUserModule,
  NbInputModule,
  NbSelectModule,
  NbTooltipModule,
  NbSpinnerModule
} from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { personalTreesRoute } from './personal-trees.route';
import { PersonalTreesComponent } from './personal-trees.component';
import { PersonalTreesResolve } from './personal-trees.resolve';
import { PersonalTreeNewComponent } from './personal-tree-new/personal-tree-new.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    JhipsterJabootSharedModule,
    BrowserAnimationsModule,
    NbCardModule,
    NbButtonModule,
    NbSpinnerModule,
    NbInputModule,
    NbSelectModule,
    NbListModule,
    NbIconModule,
    NbTooltipModule,
    NbUserModule,
    RouterModule.forChild(personalTreesRoute)
  ],
  declarations: [PersonalTreesComponent, PersonalTreeNewComponent],
  providers: [PersonalTreesResolve]
})
export class PersonalTreesModule {}
